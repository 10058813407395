import { Main } from 'wikr-core-analytics';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { closeModal } from 'redux/Modals/actions';

import { subscriptionDescriptionKey, subscriptionTitleKey, upsellDescriptionKey, upsellTitleKey } from './constants';

import { Box, Button, Text } from 'libComponents';
import Modal from 'components/Modal';

import styles from './DoublePurchasedAlert.module.css';

export type ProductTitle = { key: string; context?: string };

type DoubleAlertModalProps = {
    productTitle: string | ProductTitle[];
    payment_type: string;
};

const DoublePurchasedAlert = ({ productTitle, payment_type }: DoubleAlertModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isSubscription = payment_type === 'subscription';

    const titleKey = isSubscription ? subscriptionTitleKey : upsellTitleKey;
    const description = isSubscription ? subscriptionDescriptionKey : upsellDescriptionKey;
    const paramsKey = isSubscription ? 'subscription' : 'upsellName';

    const concatComplexName = (names: ProductTitle[]) => {
        const namesString = names.map(({ key, context }) => (context ? t(key, { context }) : t(key)));

        return namesString.length === 1 ? namesString[0] : namesString.join(' + ');
    };

    const productName = Array.isArray(productTitle) ? concatComplexName(productTitle) : productTitle;

    const handleSubmit = () => {
        Main.customData('double_purchase_error__click');

        dispatch(closeModal());
        window.location.reload();
    };

    useEffect(() => {
        Main.customData('double_purchase_error__screen__load');
    }, []);

    return (
        <Modal isOpen={true} withClose={false} centeredModal>
            <Box paddingTop={8} paddingBottom={8}>
                <span
                    role="img"
                    aria-label="hands-up"
                    style={{ fontSize: '52px', display: 'block', textAlign: 'center', marginBottom: '20px' }}
                >
                    🙌
                </span>
                <Text type="h5" center color="text-main" mb={12}>
                    {t(titleKey, {
                        [paramsKey]: t(productName),
                    })}
                </Text>
                <Text center mb={32}>
                    {t(description)}
                </Text>
                <Button
                    backgroundColor="surface-default"
                    className={styles.customButton}
                    hoverColor="surface-hovered"
                    onClick={handleSubmit}
                >
                    <Trans i18nKey="Got it" />
                </Button>
            </Box>
        </Modal>
    );
};

export default DoublePurchasedAlert;
