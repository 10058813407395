import { Main } from 'wikr-core-analytics';
import Solid from 'solid-payment';
import { ERROR_LEVELS, SENTRY_PAYMENT } from 'sentry-utils';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { selectUserId } from 'redux/User/selectors';
import { selectTestaniaName } from 'redux/Testania/selectors';
import { selectCurrentProduct, selectPaymentMethod, selectPurchaseWithoutValue } from 'redux/Payment/selectors';
import { SET_REPLACE_SUBSCRIPTION, VALIDATE_PAYMENT } from 'redux/Payment/actionTypes';
import { replaceSubscription } from 'redux/Payment/actions';

import { PAYMENT_TYPES_NAME, PAYMENT_TYPES } from 'constants/payment/payments';

import { fromPennyToInt, getPriceFromCents, isLifetime } from 'helpers/payment/paymentUtils';
import { cleanObject } from 'helpers/other/dataStructuresUtils';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';
import {
    currentProductType,
    PaymentMethod,
    ReplaceSubscriptionResponse,
    ValidatePayload,
} from 'interfaces/Payments/payments';
import { IReplaceSubscriptionDataForAnalytic } from '../user/interfaces';

function* replaceSubscriptionWatcher({ payload }: ReturnType<typeof replaceSubscription>) {
    const { system, subscriptionData, screenId, toNextPage, analytics } = payload;

    const paymentMethod: PaymentMethod = yield select(selectPaymentMethod);
    const purchaseWithoutValue: boolean = yield select(selectPurchaseWithoutValue);

    const paymentMethodNumber: number | null = PAYMENT_TYPES[paymentMethod] || null;

    const payloadValidateData: ValidatePayload = {
        paymentMethod: paymentMethodNumber,
        screenId,
        system,
        sendAnalyticToBackend: true, // TODO: not sure!!! maybe we DON'T need it at all and should use purchaseWithoutValue
        analytics,
    };

    try {
        const response: ReplaceSubscriptionResponse = yield Solid.replaceSubscription(system, subscriptionData);

        if (response) {
            payloadValidateData.data = response;

            if (!purchaseWithoutValue) {
                // @ts-ignore
                yield call(sendAnalyticPurchase, { ...response, screenId, paymentMethod });
            }

            if (purchaseWithoutValue) {
                yield put({ type: VALIDATE_PAYMENT, payload: payloadValidateData });
            }

            toNextPage(true);
        }
    } catch ({ error }) {
        sentryCaptureEvent(error, SENTRY_PAYMENT, ERROR_LEVELS.ERROR, {});
    }
}

function* sendAnalyticPurchase(payload: IReplaceSubscriptionDataForAnalytic) {
    const {
        order: { amount, currency, subscription_id, order_id },
        screenId,
        paymentMethod,
    } = payload;

    const currentProduct: currentProductType = yield select(selectCurrentProduct);
    const user_id: number = yield select(selectUserId);
    const ab_test_name: string = yield select(selectTestaniaName);
    const paymentType = currentProduct?.payment_type;

    const analyticProductName = isLifetime(paymentType) ? ` ${currentProduct?.name}` : '';

    // @ts-ignore
    const paymentMethodNumber: PaymentMethod = PAYMENT_TYPES_NAME[paymentMethod] || null;
    const content_id = `Product - Price ${fromPennyToInt(amount)}${analyticProductName}`;

    const options = {
        value: Number(getPriceFromCents(currentProduct?.ltv)),
        price: fromPennyToInt(amount),
        payment: paymentMethodNumber,
        screen_id: screenId,
        tariff: currentProduct?.id,
        currency,
        ab_test_name,
        content_id,
        user_id,
        order_id,
        subscription_id,
    };

    Main.purchase(cleanObject(options), { selectedSystems: ['amazon', 'ga'] });
}

export const replaceSubscriptionSaga = function* () {
    yield takeLatest(SET_REPLACE_SUBSCRIPTION, replaceSubscriptionWatcher);
};
