import config from 'config';

import { isProd } from '../../helpers/isProd';

import Sentry from './Sentry';

const tracesSampleRate = isProd() ? 0.05 : 1;

export default new Sentry({
    sentryDSN: config.SENTRY_DSN,
    env: config.ENV,
    release: `${config.AWS_DELIVERY_STREAM_NAME}_${config.ENV}_${config.RELEASE_DATE}`,
    tracesSampleRate,
});
