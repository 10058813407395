import { useTranslation } from 'react-i18next';

export const translate = (key: string, options?: string) => {
    const { t } = useTranslation();

    return t(key, options);
};

export const getWindowSize = (params = '(min-width: 1024px)') => {
    const mediaQuery = window.matchMedia(params);

    return mediaQuery.matches;
};

export const getLanguage = () => localStorage.getItem('language') || 'en';

export const getStartPageConfig = () => {
    const localStorageData = localStorage.getItem('config');

    const config = localStorageData && JSON.parse(localStorageData);

    return config?.start_page?.config;
};

export const removeCircleBrackets = (str: string) => str.replace(/\(.*?\)/g, '');
