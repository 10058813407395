import { Header as LibHeader, IHeader } from 'wikr-core-components';
import React, { FC } from 'react';

const Header: FC<IHeader> = ({ fixed, withShadow, backgroundColor, left, right, className }) => {
    return (
        <LibHeader
            fixed={fixed}
            withShadow={withShadow}
            backgroundColor={backgroundColor}
            left={left}
            right={right}
            className={className}
        />
    );
};

export { Header };
