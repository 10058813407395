import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { put, takeLatest, select } from 'redux-saga/effects';

import { selectCountry } from 'redux/User/selectors';
import { SET_COUNTRY } from 'redux/User/actionTypes';
import { SET_PAYMENT_CURRENCY } from 'redux/Payment/actionTypes';

import { PAYMENT_DEFAULT_CURRENCY } from 'constants/payment/payments';
import { COUNTRIES_CURRENCY_LIST } from 'constants/general/countriesData';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';

function* setCurrency() {
    try {
        const userCountry: string = yield select(selectCountry);
        const isCountryInArray: boolean = COUNTRIES_CURRENCY_LIST.some((c) => c.code === userCountry);

        if (isCountryInArray) {
            const currentCountryInList = COUNTRIES_CURRENCY_LIST.find((c) => {
                if (c.code === userCountry) {
                    return c.currency;
                }
            });

            yield put({ type: SET_PAYMENT_CURRENCY, payload: currentCountryInList?.currency });
        }

        if (!userCountry || !isCountryInArray) {
            yield put({ type: SET_PAYMENT_CURRENCY, payload: PAYMENT_DEFAULT_CURRENCY });
        }
    } catch (error) {
        sentryCaptureEvent(error, SENTRY_APP, ERROR_LEVELS.WARNING);
    }
}

export const setDefaultCurrencySaga = function* () {
    yield takeLatest(SET_COUNTRY, setCurrency);
};
