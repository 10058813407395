import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { IContainerProps } from 'types/pages/onboardingPages/componentsTypes';

import base from 'assets/styles/Common.module.css';
import styles from './Container.module.css';

const globalStyles = { ...styles, ...base };
const cx = classNames.bind(globalStyles);

function Container({
    globalClassNames = [],
    children,
    full,
    className = '',
    withoutHeader,
    withHeader,
    withBigHeader,
    withFooter,
    withoutIndent,
    emailConsentFlow,
    withoutFooter,
    withBigBottomPadding,
    smallTopPadding,
    withHeader24PX,
    withHeader28PX,
    withBar32PX,
    withoutHeaderAndWithDynamicTopPadding,
    whiteFlow,
}: IContainerProps) {
    // @ts-ignore
    const classes = globalClassNames.map((item) => ({ [item]: globalStyles[item] }));

    const containerClass = cx(
        'default',
        {
            withoutHeader,
            withHeader,
            withBigHeader,
            withFooter,
            full,
            withoutIndent,
            emailConsentFlow,
            withoutFooter,
            withBigBottomPadding,
            smallTopPadding,
            withHeader24PX,
            withHeader28PX,
            withBar32PX,
            withoutHeaderAndWithDynamicTopPadding,
            whiteFlow,
        },
        classes,
        className
    );

    const height = {
        minHeight: window.innerHeight + 'px',
    };

    return (
        <div style={height} className={containerClass}>
            {children}
        </div>
    );
}

export default memo(Container);
