import { uaParser } from 'wikr-core-analytics';

import { DEVICE_OS } from 'constants/payment/payments';

import { DeviceOs } from 'interfaces/Payments/payments';

const UAParser = new uaParser();
const userAgent = window.navigator.userAgent;

export const getDeviceOS = (): DeviceOs => {
    const userAgent = window.navigator.userAgent;
    const getOSData = UAParser.setUA(userAgent).getOS();

    return getOSData?.name?.toUpperCase() || DEVICE_OS.DEFAULT;
};

export const getDeviceType = (): string => {
    return UAParser?.setUA(userAgent)?.getDevice()?.type?.toUpperCase();
};

export const getBrowserOS = () => {
    const userAgent = window.navigator.userAgent;

    return UAParser.setUA(userAgent)?.getBrowser()?.name?.toUpperCase();
};
