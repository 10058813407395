import { OptionBox as LibOptionBox, IOptionBox } from 'wikr-core-components';
import React, { FC } from 'react';

const OptionBox: FC<IOptionBox> = ({ multiple, fixed, onClick, optionData, buttonData, className }) => {
    return (
        <LibOptionBox
            fixed={fixed}
            onClick={onClick}
            multiple={multiple}
            className={className}
            optionData={optionData}
            buttonData={buttonData}
        />
    );
};

export { OptionBox };
