import { Trans } from 'react-i18next';
import CompoundTimer from 'react-compound-timerv2';
import React, { useEffect, useState } from 'react';

import { ITimerProps } from 'types/pages/onboardingPages/componentsTypes';
import { Text } from 'libComponents';

function Timer({ className, initTimerTime, endTime, timerEndCallback, withMin = false }: ITimerProps) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isTimerEnds, setIsTimerEnds] = useState(false);

    useEffect(() => {
        if (initTimerTime <= 0) setIsTimerEnds(true);
    }, [initTimerTime]);

    const handleTimerStops = () => {
        timerEndCallback && timerEndCallback();
        setIsTimerEnds(true);
    };

    return (
        <>
            <span className={className}>
                <CompoundTimer
                    initialTime={(initTimerTime > 0 && initTimerTime) || 0}
                    startImmediately={true}
                    direction="backward"
                    checkpoints={[
                        {
                            time: endTime,
                            callback: handleTimerStops,
                        },
                    ]}
                >
                    <CompoundTimer.Minutes formatValue={(value) => `${value === 10 ? value : '0' + value}`} />
                    :
                    <CompoundTimer.Seconds formatValue={(value) => `${value < 10 ? '0' + value : value}`} />
                </CompoundTimer>
            </span>
            {withMin && (
                <Text>
                    &nbsp;
                    <Trans i18nKey="basics.min" />
                </Text>
            )}
        </>
    );
}

export default Timer;
