import { select, takeLatest } from 'redux-saga/effects';

import config from 'config';

import api from 'api';

import {
    selectCountry,
    selectEmail,
    selectIsPaid,
    selectLanguage,
    selectName,
    selectPurchaseProducts,
} from 'redux/User/selectors';
import { selectHideFrontChat, selectInitFrontChat } from 'redux/UiEffects/selectors';
import { INIT_FRONT_CHAT } from 'redux/UiEffects/actionTypes';
import { selectPaymentMethod } from 'redux/Payment/selectors';

import { cleanObject } from 'helpers/other/dataStructuresUtils';

import { PaymentMethod } from 'interfaces/Payments/payments';
import { HashedEmailResponse } from './interfaces';

function* frontChatInitialization() {
    const language: string = yield select(selectLanguage);
    const shouldInitChat: boolean = yield select(selectInitFrontChat);
    const country: string = yield select(selectCountry);
    const email: string = yield select(selectEmail);
    const name: string = yield select(selectName);
    const isPaid: boolean = yield select(selectIsPaid);
    const productList: Array<string> = yield select(selectPurchaseProducts);
    const paymentMethod: PaymentMethod = yield select(selectPaymentMethod);
    const isHideFrontChat: number = yield select(selectHideFrontChat);

    try {
        const w = window;

        if (shouldInitChat && !isHideFrontChat) {
            const hashedEmailResponse: HashedEmailResponse = yield api.user.hashedEmail();

            const customFields = cleanObject({
                platform: 'web',
                project: 'menscoach',
                country,
                language,
                isPaid,
                paymentType: paymentMethod,
                ...productList,
            });

            const initData = {
                chatId: config?.FRONT_CHAT_ID,
                useDefaultLauncher: true,
                email: email?.toLowerCase(),
                name,
                userHash: hashedEmailResponse?.data,
                customFields,
            };

            if (!w.FrontChat) {
                const insertScript = () => {
                    const d = document;
                    const script = d.createElement('script');

                    script.type = 'text/javascript';
                    script.async = true;
                    script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
                    d.body.appendChild(script);

                    script.onload = () => {
                        if (w.FrontChat) {
                            w.FrontChat('init', initData);
                        }
                    };
                };

                insertScript();
            } else {
                w.FrontChat('init', initData);
            }
        }

        if (w.FrontChat && !shouldInitChat) {
            w.FrontChat('shutdown', { clearSession: true });
        }
    } catch (e) {
        console.error(e);
    }
}

export const frontChat = function* () {
    yield takeLatest(INIT_FRONT_CHAT, frontChatInitialization);
};
