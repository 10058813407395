import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';

import rootSaga from 'redux/sagas/index';
import rootReducer from 'redux/rootReducer';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

import { PROD_LINK, PROD_LINK_NEW } from './constants/general/links';

import { blacklistTransform } from './helpers/blackListFactory';

import { sentryCaptureEvent } from './modules/ErrorHandler/ErrorBoundary';
import mirrorConfig from './mirrorConfig.json';

const hostName = window.location.host;
const isMirror = Object.keys(mirrorConfig).includes(hostName);

const sagaMiddleware = createSagaMiddleware({
    onError: (error: Error) => {
        sentryCaptureEvent(error, SENTRY_APP, ERROR_LEVELS.ERROR);
    },
});

const persistConfig = {
    key: 'root',
    storage,
    transforms: blacklistTransform(),
    blacklist: ['modals'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore() {
    const composeEnhancers =
        (hostName !== PROD_LINK &&
            hostName !== PROD_LINK_NEW &&
            !isMirror &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
        compose;

    // @ts-ignore
    const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware, thunk)));

    sagaMiddleware.run(rootSaga);
    const persistor = persistStore(store);

    return { store, persistor };
}
