import { isLifetime } from 'helpers/payment/paymentUtils';

import {
    AnalyticDataForBackend,
    CardType,
    DataAfterNormalization,
    payloadOrderInfo,
    RawData,
    ValidatePayloadAfterNormalization,
} from 'interfaces/Payments/payments';

const getAnalyticDataForBackend = (
    normalizedData: DataAfterNormalization,
    validatePayload: ValidatePayloadAfterNormalization,
    order: payloadOrderInfo,
    brand: CardType | '',
    receivedAnalyticData: RawData
) => {
    const { currency, value, subscription_price, tariff, user_id, ab_test_name, payment_type, payment_method_name } =
        normalizedData;

    const paymentType = receivedAnalyticData.currentProduct?.payment_type;
    const analyticProductName = isLifetime(paymentType) ? ` ${receivedAnalyticData.currentProduct?.name}` : '';

    const analyticDataForBackend: AnalyticDataForBackend = {
        currency,
        value,
        subscription_price,
        user_id,
        tariff,
        ab_test_name,
        order_type: payment_type,
        payment: payment_method_name,

        content_id: `Product - Price ${validatePayload.amountValue}${analyticProductName}`,
        price: validatePayload.amountValue,
        screen_id: validatePayload.pageUrl,

        subscription_id: order.subscription_id,
        order_id: order.order_id,

        card_type: brand,
    };

    return { ...normalizedData.defaultAmazonPurchaseData, ...analyticDataForBackend };
};

export default getAnalyticDataForBackend;
