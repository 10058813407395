export function gtag() {
    window?.dataLayer?.push(arguments);
}

window.gtag = gtag;

export const install = (trackingId, scriptType = 'gtag/js') => {
    if (document.getElementById(scriptType)) return;

    const { head } = document;
    const script = document.createElement('script');

    script.id = scriptType;
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://www.googletagmanager.com/${scriptType}?id=${trackingId}`;
    head.insertBefore(script, head.firstChild);

    window.dataLayer = window.dataLayer || [];

    gtag('js', new Date());
    gtag('config', trackingId);
};
