import { UpsellProduct } from 'redux/sagas/user/interfaces';

import { PRODUCT_CODES } from 'constants/payment/payments';

import { UPSELL_PRODUCTS } from 'pages/payment/Upsells/Upsell2/config';

import { ProductTitle } from 'modals/DoublePurchasedAlert/DoublePurchasedAlert';

const getProductTitle = (upsellProduct: UpsellProduct): string | ProductTitle[] => {
    if (!upsellProduct.product_code) return upsellProduct.name;

    if (upsellProduct.product_code === PRODUCT_CODES.APP_VIP_SUPPORT) {
        return 'VIP Support';
    }

    const upsell = UPSELL_PRODUCTS[upsellProduct.product_code];

    if (upsell) {
        return upsell.map(({ productName }) => ({
            key: productName,
        }));
    }

    return upsellProduct.product_code.split('__').map((code) => {
        return {
            key: 'paymentFlow.upsellSub.title',
            context: code,
        };
    });
};

export default getProductTitle;
