/* eslint-disable max-lines */
export const DEFAULT_TRIAL_PRICE = 30.99;
export const DAYS_PER_YEAR = 365;
export const DAYS_PER_MONTH = 31;
export const DAYS_PER_WEEK = 7;
export const DAYS_PER_TWO_MONTH = 62;
export const DAYS_PER_THREE_MONTH = 90;
export const DAYS_PER_FOUR_MONTH = 121;
export const DAYS_PER_FIVE_MONTH = 152;
export const DAYS_PER_SIX_MONTH = 182;
export const DAYS_PER_SEVEN_MONTH = 212;
export const DAYS_PER_EIGHT_MONTH = 243;

export const PAYMENT_TYPE_SUBSCRIPTION = 'subscription';
export const PAYMENT_TYPE_LIFETIME = 'lifetime';

export const GOOGLE_PAY_OPTION = 'google_pay';
export const CREDIT_CARD_OPTION = 'Bank_Card';
export const PAYPAL_OPTION = 'PayPal';
export const APPLE_PAY_OPTION = 'apple_pay';

export const PAYMENT_EVENT_NAMES = {
    applebtn: APPLE_PAY_OPTION,
    googlebtn: GOOGLE_PAY_OPTION,
    form: CREDIT_CARD_OPTION,
};

export const PAYMENT_TYPES = {
    BANK_CARD: 1,
    APPLE_PAY: 3,
    PAYPAL: 4,
};

export const PAYMENT_TYPES_NAME = {
    BANK_CARD: CREDIT_CARD_OPTION,
    PAYPAL: PAYPAL_OPTION,
    APPLE_PAY: APPLE_PAY_OPTION,
    ONE_CLICK_PAYMENT: PAYPAL_OPTION,
};

export const PAYMENT_TYPES_CAPITALIZED = {
    BANK_CARD: 'BANK_CARD',
    PAYPAL: 'PAYPAL',
    APPLE_PAY: 'APPLE_PAY',
    ONE_CLICK_PAYMENT: 'ONE_CLICK_PAYMENT',
};

export const PAYMENT_METHOD_NAME = {
    1: CREDIT_CARD_OPTION,
    3: APPLE_PAY_OPTION,
    4: PAYPAL_OPTION,
};

export const PAYMENT_DEFAULT_CURRENCY = {
    sign: '$',
    name: 'USD',
};

export const EURO = {
    sign: '€',
    name: 'EUR',
};

export const GBP = {
    sign: '£',
    name: 'GBP',
};

export const AUD = {
    sign: 'A$',
    name: 'AUD',
};

export const CAD = {
    sign: 'C$',
    name: 'CAD',
};

export const BRL = {
    sign: 'R$',
    name: 'BRL',
};

export const MXN = {
    sign: 'M$',
    name: 'MXN',
};

export const DEVICE_OS = {
    ANDROID: 'ANDROID',
    IOS: 'IOS',
    MAC_OS: 'MAC OS',
    DEFAULT: 'DEFAULT_DEVICE',
};

export const PAYMENT_FLOW_PERIODS = [
    DAYS_PER_MONTH,
    DAYS_PER_TWO_MONTH,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_FOUR_MONTH,
    DAYS_PER_FIVE_MONTH,
    DAYS_PER_SIX_MONTH,
    DAYS_PER_SEVEN_MONTH,
    DAYS_PER_EIGHT_MONTH,
    DAYS_PER_YEAR,
    DAYS_PER_WEEK,
];

export const INTRODUCTORY_B_FLOW_PAYMENT_ID = 'payment_B1_checkout_Bravo1';
export const INTRODUCTORY_C_FLOW_PAYMENT_ID = 'payment_C1_checkout_Bravo1';

export const PRODUCT_CODES = {
    APP_FULL_ACCESS: 'app_full_access',
    APP_VIP_SUPPORT: 'vip_support',
    APP_GUIDE_WEIGHT_LOSS: 'weight_loss_gd',
    APP_GUIDE_FAT_BURNING: 'fat_burning_gd',
    APP_GUIDE_ALL: 'fat_burning_gd__weight_loss_gd',
    APP_GUIDE_WEIGHT_LOSS_SALE: 'weight_loss_gd_full_offer',
    APP_GUIDE_FAT_BURNING_SALE: 'fat_burning_gd_full_offer',
    APP_GUIDE_ALL_SALE: 'fat_burning_gd__weight_loss_gd_full_offer',
    APP_GUIDE_WEIGHT_LOSS_FULL: 'weight_loss_gd_full',
    APP_GUIDE_FAT_BURNING_FULL: 'fat_burning_gd_full',
    APP_GUIDE_ALL_FULL: 'fat_burning_gd__weight_loss_gd_full',
};

export const BANK_CARD_CUSTOM_BUTTON_ID = 'customSubmitButton';

// 2.01 - Invalid Data
// 2.03 - Invalid Currency
// 2.05 - Order not Found
// 2.10 - Invalid 3DS flow on the merchant side
// 2.15 - SCA require 3D authentication
const SOLID_INVALID_DATA_CODES = ['2.01', '2.03', '2.05', '2.10', '2.15'];

// 5.02 - Invalid Card Token
// 5.03 - Application Error
// 5.04 - Merchant is not configured yet
// 5.05 - Merchant is not activated yet
const SOLID_PROCESSING_ERRORS_CODES = ['5.02', '5.03', '5.04', '5.05'];

// 7.01 - Card Token not found
// 7.02 - Google payment error
// 7.03 - Smart cascade decline
// 7.04 - 3DS cascade to 2D
// 7.05 - Apple online payment error
// 7.06 - Token generation error
const SOLID_DECLINED_PAYMENTS_CODES = ['7.01', '7.02', '7.03', '7.04', '7.05', '7.07'];

export const SOLID_ERROR_CODES = [
    ...SOLID_INVALID_DATA_CODES,
    ...SOLID_PROCESSING_ERRORS_CODES,
    ...SOLID_DECLINED_PAYMENTS_CODES,
];

export const US_DISCLAIMER = 'Solidgate LLC, Nevada, USA';
export const EU_DISCLAIMER = 'WELLTIKA CONTENT PROVIDER DMCC, United Arab Emirates';
