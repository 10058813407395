import type { RouteProps } from 'react-router-dom';
// eslint-disable-next-line import/named
import { Redirect } from 'react-router-dom';
import React from 'react';

import lazyWithRetry from 'helpers/lazyWithRetry';
import { generateRoute, pathWithRegularExpression } from '../helpers/helpers';

import { allPagesRoutes } from '../publicRoutesMap';

const PreloadTestania = lazyWithRetry(() => import('pages/other/PreloadTestania'));

const routes: Array<RouteProps> = [
    {
        path: pathWithRegularExpression(''),
        render: (props: any) => <PreloadTestania {...props} />,
        exact: true,
    },
];

for (const item of allPagesRoutes) {
    const { route, path } = item;

    const payload = generateRoute(route, path);

    routes.push(payload);
}

routes.push({
    path: '*',
    render: () => <Redirect to="/en" />,
});

export default routes;
