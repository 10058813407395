import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { setCustomPaymentButton } from 'redux/Payment/actions';

export const useCustomPaymentButton = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCustomPaymentButton(true));

        return () => {
            dispatch(setCustomPaymentButton(false));
        };
    }, []);
};
