import { BLACK_LIST_HANDLED_ERROR_NAMES, SENTRY_APP, IGNORE_URLS_LIST, IGNORE_ERRORS_LIST } from 'sentry-utils';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { Breadcrumb, BreadcrumbHint, EventHint, SeverityLevel, Event } from '@sentry/react';

import { checkFbBots } from 'helpers/other/commonUtils';
import getAmazonDefaultData from 'helpers/analytic/getAmazonDefaultData';
import { checkIsOldDevices, getCustomTags } from './helpers';

import { ISentry, errorType, Extras, Tags, UserInfo, IError } from './types';

export default class SentryClient {
    sentryDSN: string;
    env: 'stage' | 'prod' | string;
    release?: string;
    tracesSampleRate?: number;
    private isInited: boolean;
    private sentry: typeof Sentry;

    constructor({ sentryDSN, env, release, tracesSampleRate }: ISentry) {
        this.sentryDSN = sentryDSN;
        this.env = env || 'stage';
        this.release = release || '1.0.0';
        this.tracesSampleRate = tracesSampleRate || 1.0;
        this.isInited = false;
        this.sentry = Sentry;
    }

    init() {
        if (this.isInited) return;

        Sentry.init({
            dsn: this.sentryDSN,
            integrations: [new Integrations.BrowserTracing()],
            environment: this.env,
            debug: false,
            release: this.release,
            tracesSampleRate: this.tracesSampleRate,
            maxBreadcrumbs: 10,
            beforeBreadcrumb: (breadcrumb: Breadcrumb, hint: BreadcrumbHint) =>
                SentryClient.prepareBreadcrumb(breadcrumb, hint),
            beforeSend: (event: Event, hint: EventHint) => SentryClient.filterEventsBeforeSend(event, hint),
            ignoreErrors: IGNORE_ERRORS_LIST || [],
            denyUrls: IGNORE_URLS_LIST || [],
        });

        this.isInited = true;
    }

    public logError(error: IError, type: errorType, errorLevel: SeverityLevel, extras?: Extras, customTags?: Tags) {
        if (type === null) type = SENTRY_APP;

        const customTagsArr = customTags || getCustomTags(error, type, extras);

        SentryClient.getAnalyticData().then((analyticData) =>
            this.sentry.withScope((scope: Sentry.Scope) => {
                // TODO: maybe we don't need extras &&
                extras && scope.setExtras({ ...extras, ...analyticData });

                scope.setTag('ERROR_LEVEL', errorLevel);
                scope.setTag('ERROR_TYPE', type);
                scope.setTag('aws_id', analyticData?.aws_id || null);
                scope.setLevel(errorLevel);

                customTagsArr.length && customTagsArr.forEach(([tag, value]) => scope.setTag(tag, value));

                this.sentry.captureMessage(SentryClient.prepareErrorByCode(error, type));
            })
        );
    }

    private static prepareErrorByCode(error: IError, type: errorType) {
        return `[${type}] | : ${JSON.stringify(error)} | ${error}`;
    }

    private static async getAnalyticData() {
        return await getAmazonDefaultData();
    }

    private static prepareBreadcrumb(breadcrumb: Sentry.Breadcrumb, hint: Sentry.BreadcrumbHint) {
        if (breadcrumb.level === 'error' || breadcrumb.type === 'http') {
            return { ...breadcrumb, ...hint };
        }

        return null;
    }

    public setUser(userData: UserInfo | null) {
        this.sentry.setUser(userData);
    }

    private static filterEventsBeforeSend(event: Event, hint: EventHint) {
        const error = hint.originalException;

        if (
            (typeof error === 'string' && SentryClient.matchSubstring(error)) ||
            checkIsOldDevices(event) ||
            checkFbBots()
        ) {
            return null;
        }

        return event;
    }

    private static matchSubstring(error: string) {
        return BLACK_LIST_HANDLED_ERROR_NAMES.some((item) => error.includes(item));
    }
}

export { errorType, Extras };
