import { LTV_COEFFICIENT_KEY, DEFAULT_LTV_COEFFICIENT } from 'constants/user';
import { DAYS_PER_MONTH } from 'constants/product/periods';
import { PAYMENT_FLOW_PERIODS, PRODUCT_CODES } from 'constants/payment/payments';

import { getPriceFromCents } from '../paymentUtils';

export const checkProductPeriods = ({ periodsArr }) => {
    return periodsArr.every((period) => PAYMENT_FLOW_PERIODS.includes(period));
};

export const checkLtv = ({ products }) => {
    return products.every((product) => typeof product?.ltv === 'number' && product?.ltv > 0);
};

export const getArrayFromObjectByFieldName = (arrObjects, key) => {
    const resultArr = [];

    arrObjects.forEach((element) => {
        resultArr.push(element[key]);
    });

    return resultArr;
};

export const checkPreselectedField = ({ products, withImportantOrder }) => {
    let productsList = products;

    if (withImportantOrder) {
        const averageProductValue = products.length / 2;

        productsList = products.slice(0, averageProductValue);
    }

    return productsList.some((product) => product.is_preselected === true);
};

export const checkValuePairsIdentityByFieldName = ({ products, fieldName }) => {
    const countValues = products.reduce((acc, curr) => {
        const fieldValue = curr[fieldName];

        acc[fieldValue] = (acc[fieldValue] || 0) + 1;

        return acc;
    }, {});

    return Object.values(countValues).every((value) => value === 2);
};

export const checkProductsDividedByTwo = ({ products }) => {
    return products.length % 2 === 0;
};

export const checkProductsCode = ({ products }) => {
    return products.every((item) => PRODUCT_CODES.includes(item.name));
};

export const calculateLTV = (productLTV, productPeriod) => {
    const coefficient =
        productPeriod !== DAYS_PER_MONTH
            ? DEFAULT_LTV_COEFFICIENT
            : Number(localStorage.getItem(LTV_COEFFICIENT_KEY)) || DEFAULT_LTV_COEFFICIENT;

    return getPriceFromCents(Number(productLTV) * coefficient);
};
