import { Main } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_ONE_TRUST } from 'sentry-utils';

import { TARGETING_COOKIE } from 'constants/analytic/analytic';

import sentry from 'services/Sentry/SentryInstance';

import { getAllAnalyticInitList, getAnalyticInitList } from './helper';

const stringToArray = (str: string) => (str ? str.slice(1, -1).split(',') : []);

const isAcceptedAllCookies = (cookieList: string[]) => cookieList.includes(TARGETING_COOKIE);

export default class OneTrust {
    #instance;

    private isAllowedAll;
    private isBannerClosed;
    private acceptedCookieList;

    constructor(OneTrust: any) {
        this.#instance = OneTrust;
        this.isAllowedAll =
            OneTrust?.IsAlertBoxClosed() && isAcceptedAllCookies(stringToArray(window?.OptanonActiveGroups));
        this.isBannerClosed = OneTrust?.IsAlertBoxClosed();
        this.acceptedCookieList = OneTrust?.IsAlertBoxClosed() ? stringToArray(window?.OptanonActiveGroups) : [];
    }

    setError(methodName: string) {
        sentry.logError(
            new Error(`OneTrust method ${methodName} don't work`),
            SENTRY_ONE_TRUST,
            ERROR_LEVELS.CRITICAL,
            {}
        );
    }

    consentHandler(oneTrustEvent: { detail: string[] }) {
        const { detail } = oneTrustEvent;

        if (!this.isBannerClosed) {
            this.acceptedCookieList = detail;
        }

        const cookieList = this.isBannerClosed ? this.acceptedCookieList : detail;

        this.isAllowedAll = isAcceptedAllCookies(cookieList);

        const activeSystems = getAnalyticInitList(cookieList);

        if (this.isAllowedAll && !this.isBannerClosed) {
            if (activeSystems.length) {
                Main.initSystems({ activeSystems });
                Main.trackNew({ eventData: 'full_cookie_consent' });

                this.isBannerClosed = true;
            }
        } else if (activeSystems.length && this.isBannerClosed) {
            Main.initSystems({ activeSystems });
        }
    }

    init() {
        const isOneTrustAccepted = localStorage.getItem('oneTrustAccepted');

        if (isOneTrustAccepted === 'accept') {
            this.#instance?.Close();
            const activeSystems = getAllAnalyticInitList();

            Main.initSystems({ activeSystems });

            this.isAllowedAll = true;
            this.#instance?.AllowAll ? this.#instance.AllowAll() : this.setError('AllowAll');
        } else if (isOneTrustAccepted === 'reject') {
            this.#instance?.Close();
            this.#instance?.RejectAll ? this.#instance?.RejectAll() : this.setError('rejectAll');
        } else if (this.isBannerClosed) {
            this.consentHandler({ detail: this.acceptedCookieList });
        } else {
            this.#instance?.OnConsentChanged
                ? this.#instance.OnConsentChanged(this.consentHandler)
                : this.setError('OnConsentChanged');
        }
    }

    rejectAll() {
        this.#instance?.RejectAll ? this.#instance?.RejectAll() : this.setError('rejectAll');
    }

    allowAll(isRemarketingFlow = false) {
        if (!this.isAllowedAll || isRemarketingFlow) {
            const activeSystems = getAllAnalyticInitList();

            Main.initSystems({ activeSystems });

            this.isAllowedAll = true;
            this.#instance?.AllowAll ? this.#instance.AllowAll() : this.setError('AllowAll');
        }
    }
}
