import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { ITitleProps } from 'types/pages/onboardingPages/componentsTypes';

import base from 'assets/styles/Common.module.css';
import styles from './Title.module.css';

const globalStyles = { ...styles, ...base };
const cx = classNames.bind(globalStyles);

function Title({
    globalClassNames = [],
    children,
    h1,
    h2,
    h3,
    h4,
    h5,
    grey,
    centered,
    withoutTextTransform,
    lowerCase,
    warning,
    className,
    black,
}: ITitleProps) {
    // @ts-ignore
    const classes = globalClassNames.map((item) => ({ [item]: globalStyles[item] }));

    const titleClass = cx(
        'default',
        {
            h1,
            h2,
            h3,
            h4,
            h5,
            grey,
            centered,
            withoutTextTransform,
            lowerCase,
            warning,
            black,
        },
        classes,
        className
    );

    return <h3 className={titleClass}>{children}</h3>;
}

export default memo(Title);
