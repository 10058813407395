import Date from './date/date';

const getTimerTime = (params) => {
    const minutesTimer = params?.isFiveMinTimer ? 5 : 10;
    const key = params?.key;
    const isExistTimer = params?.isExistTimer;

    const dateFrom = Date.getTime();
    const dateTo = Date.addMinutes(minutesTimer);

    const timerKey = key ? `timerTime-${key}` : 'timerTime';

    if (isExistTimer) {
        return getDifferent(dateFrom, isExistTimer);
    }

    if (localStorage.getItem(timerKey)) {
        return getDifferent(dateFrom, localStorage.getItem(timerKey));
    } else {
        localStorage.setItem(timerKey, dateTo);

        return getDifferent(dateFrom, dateTo);
    }
};

const getDifferent = (dateFrom, dateTo) => Date.getDiff(dateFrom, dateTo);

export default getTimerTime;
