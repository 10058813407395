import { Switcher as LibSwitcher } from 'wikr-core-components';
import React, { FC } from 'react';

import { translate } from 'helpers/utils';

interface ISwitcherCustom {
    data: Array<string>;
    onChange: (e: React.ChangeEvent) => void;
    defaultUnit?: string;
    rounded?: boolean;
    mb?: number;
    dataLocators?: Array<string>;
    className?: Record<string, unknown> & string;
}

const Switcher: FC<ISwitcherCustom> = ({ data, defaultUnit, rounded, onChange, mb, dataLocators, className }) => {
    const translatedData = data && data.map((item) => translate(item));

    return (
        <LibSwitcher
            data={data}
            translatedData={translatedData}
            dataLocators={dataLocators}
            defaultUnit={defaultUnit}
            rounded={rounded}
            onChange={onChange}
            mb={mb}
            className={className}
        />
    );
};

export { Switcher };
