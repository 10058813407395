/* eslint-disable max-lines */
import { Main } from 'wikr-core-analytics';
import { OneClickPayment } from 'solid-payment/src/interfaces/payment';
import Solid from 'solid-payment';
import { ERROR_LEVELS, SENTRY_PAYMENT } from 'sentry-utils';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { showModal } from 'redux/UiEffects/actions';
import { selectTestaniaName } from 'redux/Testania/selectors';
import {
    Currency,
    IOneClickPaymentDataForAnalytic,
    IValidateOneClickPaymentMeta,
    UpsellProduct,
} from 'redux/sagas/user/interfaces';
import {
    selectCurrency,
    selectCurrentProduct,
    selectOneClickPaymentPrice,
    selectPaymentMethod,
    selectUpsellId,
    selectUpsellLtv,
    selectUpsellPaymentType,
    selectUpsellProduct,
    selectUpsellProductCode,
    selectUpsellProductName,
    selectUpsellProductPeriod,
    selectValidPaymentInfo,
} from 'redux/Payment/selectors';
import * as actionTypes from 'redux/Payment/actionTypes';
import { initOneClickPayment } from 'redux/Payment/actions';
import { ModalName } from 'redux/Modals/types';
import { openModal } from 'redux/Modals/actions';

import { PAYMENT_TYPE_LIFETIME, PAYMENT_TYPE_SUBSCRIPTION, PAYMENT_TYPES_NAME } from 'constants/payment/payments';
import { DOUBLE_PURCHASE_ERROR } from 'constants/errors/errors';

import sentry from 'services/Sentry/SentryInstance';

import { getPriceFromCents, isLifetime, isSubscription, isVipSupport } from 'helpers/payment/paymentUtils';
import { calculateLTV } from 'helpers/payment/PaymentFlow/utils';
import { cleanObject } from 'helpers/other/dataStructuresUtils';
import getProductTitle from 'helpers/getProductTitle';

import { CurrentProduct } from 'types/payments/payments';
import { PaymentData } from 'types/payments/paymentApiInterfaces';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';
import { paymentInfo } from 'interfaces/Store/paymentStore';
import { ContentIdMetaType, OrderType, PaymentMethod } from 'interfaces/Payments/payments';

export function* init({ payload }: ReturnType<typeof initOneClickPayment>) {
    const meta: OneClickPayment = yield call(getMetaByType);
    const paymentInfo: paymentInfo = yield select(selectValidPaymentInfo);
    const paymentType: OrderType = yield select(selectUpsellPaymentType);
    const isTypeSubscription: boolean = isSubscription(paymentType);
    const isTypeLifetime: boolean = isLifetime(paymentType);
    const currentProduct: CurrentProduct = yield select(selectCurrentProduct);
    const productId: string = yield select(selectUpsellId);
    const upsellLtv: number = yield select(selectUpsellLtv);
    const upsellProductPeriod: number = yield select(selectUpsellProductPeriod);
    const upsellProduct: UpsellProduct = yield select(selectUpsellProduct);

    try {
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

        const response: PaymentData = yield Solid.oneClickPayment(paymentInfo.system, meta);

        if (response.error) {
            yield call(errorHandler);
        }

        // first of all we need try to take subscription_id(payment_type = 'subscription')
        // if payment_type is lifetime, get order_id, get the subscription_id first of all, do not swap
        const order = response?.order;
        const orderId = order?.order_id;
        const subscriptionId = order?.subscription_id;

        const validateMeta: IValidateOneClickPaymentMeta = {
            ...(isTypeLifetime && { orderId: orderId as string }),
            ...(isTypeSubscription && { subscriptionId: subscriptionId as string, orderId: orderId as string }),
            payload,
        };

        const data: IOneClickPaymentDataForAnalytic = {
            value: calculateLTV(upsellLtv, upsellProductPeriod),
            order_id: validateMeta?.orderId,
            screenId: validateMeta?.payload?.screenId,
            payment_type: paymentType,
            subscription_id: validateMeta?.subscriptionId,
            productId,
        };

        yield put({ type: actionTypes.SET_PAYMENT_DATA, payload: response });
        yield call(sendAnalyticPurchase, data);
        yield call(setUpsellId, productId);
        yield call(moveToNextPage, true, validateMeta?.payload.toNextPage);
    } catch (error) {
        if (error?.message === DOUBLE_PURCHASE_ERROR) {
            yield put(
                openModal(ModalName.doublePurchasedAlert, {
                    productTitle: getProductTitle(upsellProduct),
                    payment_type: upsellProduct.payment_type,
                })
            );
        }

        yield call(errorHandler);

        sentry.logError(error, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, {
            ...currentProduct,
            ...payload,
        });
    } finally {
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
    }
}

function getContentId(contentIdMeta: ContentIdMetaType): string {
    const { paymentType, productId, price, upsellProductName, productCode } = contentIdMeta;
    let contentId = '';

    if (isLifetime(paymentType)) {
        contentId = `Product - Price ${price} ${productId}`;
    }

    if (isSubscription(paymentType)) {
        contentId = upsellProductName;
    }

    if (isVipSupport(productCode)) {
        contentId = `Product - Price ${price}`;
    }

    return contentId;
}

export function* sendAnalyticPurchase(payload: IOneClickPaymentDataForAnalytic) {
    const cents: number = yield select(selectOneClickPaymentPrice);
    const currency: Currency = yield select(selectCurrency);
    const abTestName: string = yield select(selectTestaniaName);
    const paymentType: OrderType = yield select(selectUpsellPaymentType);
    const paymentMethod: PaymentMethod = yield select(selectPaymentMethod);
    const upsellProductName: string = yield select(selectUpsellProductName);
    const productCode: string = yield select(selectUpsellProductCode);

    const isTypeSubscription: boolean = isSubscription(paymentType);

    const price: number = getPriceFromCents(cents);
    const paymentMethodName: string | null = PAYMENT_TYPES_NAME[paymentMethod] || null;

    const { value, order_id, payment_type, subscription_id, productId } = payload;

    localStorage.setItem('paymentType', payment_type);

    const contentIdMeta = {
        paymentType,
        productId,
        price,
        upsellProductName,
        productCode,
    };
    const analyticData = {
        currency: currency.name,
        value,
        content_id: getContentId(contentIdMeta),
        tariff: isTypeSubscription ? productId : PAYMENT_TYPE_LIFETIME,
        price,
        payment: paymentMethodName,
        order_id,
        screen_id: payload?.screenId,
        ab_test_name: abTestName,
        order_type: payment_type,
        ...(isTypeSubscription && {
            subscription_id,
            subscription_price: price,
        }),
    };

    Main.purchase(cleanObject(analyticData));
}

export function* getMetaByType() {
    const paymentType: OrderType = yield select(selectUpsellPaymentType);
    const productCode: string = yield select(selectUpsellProductCode);

    if (paymentType === PAYMENT_TYPE_SUBSCRIPTION) {
        const productId: string = yield select(selectUpsellId);

        return {
            product_id: productId,
            product_code: productCode,
        };
    }

    if (paymentType === PAYMENT_TYPE_LIFETIME) {
        const oneClickPrice: number = yield select(selectOneClickPaymentPrice);

        return {
            price: oneClickPrice,
            product_code: productCode,
        };
    }

    sentryCaptureEvent(new Error('Cannot select paymentType'), SENTRY_PAYMENT, ERROR_LEVELS.ERROR);
}

export function* moveToNextPage(validateStatus: boolean, toNextPage: (status: boolean) => void) {
    yield call(toNextPage, validateStatus);
}

export function* showErrorModal(isOpen: boolean) {
    yield put(showModal(isOpen));
}

export function* errorHandler() {
    yield call(showErrorModal, true);
    yield call(setUpsellId, '100');
}

export function* setUpsellId(id: string) {
    yield put({ type: actionTypes.SET_UPSELL_PRODUCT_ID, payload: id });
}

export const initOneClickPaymentSaga = function* () {
    yield takeLatest(actionTypes.INIT_ONE_CLICK_PAYMENT, init);
};
