import { OnBoardingConfigState } from 'interfaces/Testania/testania';

import * as action from './actions';

import * as actionTypes from './actionTypes';

import { InferValueTypes } from 'types/commonInterfaces';

const initialState: OnBoardingConfigState = {
    onboardingConfig: [],
    testaniaName: '',
    testaniaLoginStatus: null,
    completeOBs: [''],
    currentBranchName: null,
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const TestaniaReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SET_ONBOARDING_CONFIG:
            return { ...state, onboardingConfig: action.payload };

        case actionTypes.SET_TESTANIA_NAME:
            localStorage.setItem('testania_name', action.payload);

            return { ...state, testaniaName: action.payload };

        case actionTypes.SET_TESTANIA_LOGIN_STATUS:
            // @ts-ignore
            localStorage.setItem('loginStatus', action.payload);

            return { ...state, testaniaLoginStatus: action.payload };

        case actionTypes.SET_COMPLETE_OBS:
            localStorage.setItem('completedOBs', action.payload);

            return { ...state, completeOBs: [...state.completeOBs, action.payload] };

        case actionTypes.SET_CURRENT_BRANCH_NAME:
            localStorage.setItem('currentBranchName', action.payload);

            return { ...state, currentBranchName: action.payload };

        case actionTypes.CLEAR_COMPLETE_OBS:
            return { ...state, completeOBs: [] };

        default:
            return state;
    }
};

export default TestaniaReducer;
