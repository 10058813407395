import { ModalOpenStatuses } from 'interfaces/Store/paymentStore';

export const PAYMENT_MODAL = 'paymentModal';
export const EXCLUSIVE_OFFER_MODAL = 'exclusiveOfferModal';
export const CHECKOUT_MODAL = 'checkoutModal';

export const DEFAULT_MODAL_OPEN_STATUS_VALUES: ModalOpenStatuses = {
    paymentModal: false,
    exclusiveOfferModal: false,
    checkoutModal: false,
};
