import { allPagesRoutes } from 'router/publicRoutesMap';

import { VALIDATION_CONFIG_REGEXP } from 'constants/general/regExps';

import { replaceUnderscoreToDash } from 'helpers/other/transformationUtils';
import { isEmpty } from 'helpers/other/dataStructuresUtils';

const validationConfigRegExp = new RegExp(VALIDATION_CONFIG_REGEXP);

const checkFlow = (flow) => {
    if (Array.isArray(flow)) {
        for (const { id } of flow) {
            if (!validationConfigRegExp.test(id)) {
                return false;
            }
        }
    } else {
        return validationConfigRegExp.test(flow?.id);
    }

    return true;
};

const checkIds = (pagesData) => {
    const routesMap = [...allPagesRoutes];

    for (const { id } of pagesData) {
        if (!routesMap.some(({ route }) => route === replaceUnderscoreToDash(id))) {
            console.error(`%c Invalid page id: ${id}`, 'color:red;');

            return false;
        }
    }

    return true;
};

export const checkConfig = (config) => {
    const isValidStartFlow = checkFlow(config?.start_page);
    const isValidOnboardingFlow = checkFlow(config?.onboarding_flow);
    const isValidPaymentFlow = checkFlow(config?.payment_flow);

    return isValidStartFlow && isValidOnboardingFlow && isValidPaymentFlow;
};

export const checkPagesId = (config) => {
    const pagesData = [];

    config?.onboarding_flow && pagesData.push(...config?.onboarding_flow);
    config?.payment_flow && pagesData.push(...config?.payment_flow);
    !isEmpty(config?.start_page) && pagesData.push(config?.start_page);

    return checkIds(pagesData);
};
