import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { changePaymentMethod } from 'redux/Payment/actions';
import { selectGoal } from 'redux/Onboadring/selectors';

import { PAYMENT_TYPES_CAPITALIZED } from 'constants/payment/payments';

import { translate } from 'helpers/utils';
import { getPriceFromCentsFixed } from 'helpers/payment/paymentUtils';
import { dateNormalizerWithoutPlan } from 'helpers/normalizer/normalizer';
import sendAnalyticsWithEventLabel from 'helpers/analytic/sendAnalyticsWithEventLabel';

import { CurrentProduct } from 'types/payments/payments';
import { IAnalytic } from 'types/commonInterfaces';

import useCurrencyData from './useCurrencyData';

export interface useCheckoutHandlerProps {
    closeHandler: () => void;
    productInfo: CurrentProduct;
    analytic: IAnalytic;
    checkoutName: string;
    isOpen?: boolean;
    isPaymentWithTrial?: boolean;
    paymentType?: string;
}

export const useCheckoutLogic = ({
    productInfo: { price, start_price, price_per_state, start_price_per_state, trial },
    analytic,
    checkoutName,
    closeHandler,
    isOpen,
    isPaymentWithTrial,
    paymentType,
}: useCheckoutHandlerProps) => {
    const dispatch = useDispatch();
    const actualPaymentType = paymentType ?? PAYMENT_TYPES_CAPITALIZED.PAYPAL;
    const [activeOption, setActiveOption] = useState(actualPaymentType);

    const goal = useSelector(selectGoal);
    const plan = translate(dateNormalizerWithoutPlan(trial));
    const { currencyName, currencySign } = useCurrencyData();

    const isTrialProduct = trial === 7 && isPaymentWithTrial;
    const isIntroDisclaimer = start_price > price;
    const fullPrice = getPriceFromCentsFixed(price);
    const personalizeFullPrice = getPriceFromCentsFixed(start_price);

    const isRegularProduct = start_price === price;
    const isDiscount = price_per_state < start_price_per_state;
    const discountPrice = (Number(personalizeFullPrice) - Number(fullPrice)).toFixed(2);
    const discountPercent = Math.round((1 - Number(fullPrice) / Number(personalizeFullPrice)) * 100);
    const pricePerDay = isDiscount
        ? getPriceFromCentsFixed(price_per_state)
        : getPriceFromCentsFixed(start_price_per_state);

    useEffect(() => {
        if (isOpen) {
            initPaymentMethod(activeOption);

            analytic.track(checkoutName);
            analytic.track('checkout');
        }
    }, [isOpen]);

    const sendAnalyticData = (eventValue: string) => {
        sendAnalyticsWithEventLabel({
            url: checkoutName,
            eventValue: eventValue,
        });
    };

    const initPaymentMethod = (type: string) => {
        setActiveOption(type);

        dispatch(changePaymentMethod(type));
    };

    const handleChange = (type: string) => {
        if (activeOption !== type) {
            initPaymentMethod(type);

            sendAnalyticData(type);
        }
    };

    const handleOnClose = () => {
        closeHandler?.();

        sendAnalyticsWithEventLabel({
            url: `close_${checkoutName}`,
            eventValue: 'close',
        });
    };

    return {
        handleOnClose,
        handleChange,
        discountPrice,
        discountPercent,
        plan,
        isIntroDisclaimer,
        currencyName,
        currencySign,
        isTrialProduct,
        isRegularProduct,
        isDiscount,
        pricePerDay,
        personalizeFullPrice,
        fullPrice,
        activeOption,
        goal,
    };
};
