import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { put, takeLatest } from 'redux-saga/effects';

import api from 'api';

import { showModal } from 'redux/UiEffects/actions';
import { SET_PULSE_MAILING_ACTIVE } from 'redux/Onboadring/actionTypes';
import { setPulseMailingActive } from 'redux/Onboadring/actions';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';

function* updateUserSendPulseStatusWorker({ payload }: ReturnType<typeof setPulseMailingActive>) {
    try {
        yield api.user.update({ consent_marketing: payload });
    } catch (error) {
        yield put(showModal(true));

        sentryCaptureEvent(error, SENTRY_APP, ERROR_LEVELS.ERROR);
    }
}

export const updateUserMailingStatus = function* () {
    yield takeLatest(SET_PULSE_MAILING_ACTIVE, updateUserSendPulseStatusWorker);
};
