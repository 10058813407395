import { PRODUCT_CODES } from 'constants/payment/payments';

import weightLossImg from 'assets/img/emoji/fire.webp';
import intensiveImg from 'assets/img/emoji/arm.webp';

import { IUpsellConfig } from 'types/pages/startPages/commonTypes';

export const UPSELL_PRODUCTS: IUpsellConfig = {
    [PRODUCT_CODES.APP_GUIDE_FAT_BURNING]: [
        {
            name: 'paymentFlow.upsell.products.fatBurnProgram',
            img: intensiveImg,
            alt: 'arm',
            productName: 'cabinet.upsellProducts.fatBurningGuide',
        },
    ],
    [PRODUCT_CODES.APP_GUIDE_WEIGHT_LOSS]: [
        {
            name: 'paymentFlow.upsell.products.weightLossProgram',
            img: weightLossImg,
            alt: 'fire',
            productName: 'cabinet.upsellProducts.weightLossGuide',
        },
    ],
    [PRODUCT_CODES.APP_GUIDE_ALL]: [
        {
            name: 'paymentFlow.upsell.products.fatBurn',
            img: intensiveImg,
            alt: 'arm',
            productName: 'cabinet.upsellProducts.fatBurningGuide',
        },
        {
            name: 'paymentFlow.upsell.products.weightLoss',
            img: weightLossImg,
            alt: 'fire',
            productName: 'cabinet.upsellProducts.weightLossGuide',
        },
    ],
};

export const UPSELL_PRODUCTS_FULL: IUpsellConfig = {
    [PRODUCT_CODES.APP_GUIDE_FAT_BURNING_FULL]: [
        {
            name: 'paymentFlow.upsell.products.fatBurnProgram',
            img: intensiveImg,
            alt: 'arm',
            productName: 'paymentFlow.upsell.products.fatBurnProgram',
        },
    ],
    [PRODUCT_CODES.APP_GUIDE_WEIGHT_LOSS_FULL]: [
        {
            name: 'paymentFlow.upsell.products.weightLossProgram',
            img: weightLossImg,
            alt: 'fire',
            productName: 'paymentFlow.upsell.products.weightLossProgram',
        },
    ],
    [PRODUCT_CODES.APP_GUIDE_ALL_FULL]: [
        {
            name: 'paymentFlow.upsell.products.fatBurn',
            img: intensiveImg,
            alt: 'arm',
            productName: 'paymentFlow.upsell.products.fatBurn',
        },
        {
            name: 'paymentFlow.upsell.products.weightLoss',
            img: weightLossImg,
            alt: 'fire',
            productName: 'paymentFlow.upsell.products.weightLoss',
        },
    ],
};
