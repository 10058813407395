import { Option as LibOption, IOption } from 'wikr-core-components';
import React, { FC } from 'react';

const Option: FC<IOption> = ({
    title,
    subtitle,
    onClick,
    value,
    leftIcon,
    leftSvg,
    leftImage,
    rightImage,
    rightIcon,
    rightSvg,
    indicated,
    rightCheckbox,
    leftCheckbox,
    titleType,
    mb,
    dataLocator,
    short,
    className,
    borderColor,
    borderWidth,
}) => {
    return (
        <LibOption
            title={title}
            subtitle={subtitle}
            onClick={onClick}
            value={value}
            leftIcon={leftIcon}
            leftSvg={leftSvg}
            leftImage={leftImage}
            rightImage={rightImage}
            rightIcon={rightIcon}
            rightSvg={rightSvg}
            indicated={indicated}
            rightCheckbox={rightCheckbox}
            leftCheckbox={leftCheckbox}
            titleType={titleType}
            mb={mb}
            dataLocator={dataLocator}
            short={short}
            className={className}
            borderColor={borderColor}
            borderWidth={borderWidth}
        />
    );
};

export { Option };
