import { ComponentType, ComponentProps } from 'react';

import DoublePurchasedAlert from 'modals/DoublePurchasedAlert/DoublePurchasedAlert';

export enum ModalName {
    doublePurchasedAlert = 'doublePurchasedAlert',
}

export type ModalPropsType = {
    [ModalName.doublePurchasedAlert]: ComponentProps<typeof DoublePurchasedAlert>;
};

export type ModalsMapType = {
    [key in ModalName]: ComponentType<ModalPropsType[key]>;
};
