import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { selectInlineTheme } from 'redux/UiEffects/selectors';
import { initFrontChat } from 'redux/UiEffects/actions';

import { setupInlineStyles } from 'helpers/theme';

const useOnboardingDependencies = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(initFrontChat(false));
    }, []);

    const inlineStyles = useSelector(selectInlineTheme);

    useEffect(() => {
        if (inlineStyles) setupInlineStyles(inlineStyles);
    }, [inlineStyles]);
};

export default useOnboardingDependencies;
