export const UpsellPages = [
    {
        path: 'payment/Upsells/Upsell2',
        route: 'upsell-2',
    },
    {
        path: 'payment/Upsells/UpsellSub',
        route: 'upsell-sub',
    },
];
