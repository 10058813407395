import { fromPennyToInt } from 'helpers/payment/paymentUtils';

import { ContentIdConfig } from 'interfaces/Payments/payments';

const getContentId = (config: ContentIdConfig): string => {
    if (config.isIntroductory) {
        return `Product - Introductory Price ${fromPennyToInt(config.amount)} - Subscription price ${fromPennyToInt(
            config.introductorySubscriptionPrice
        )}`;
    }

    return `Product - Price ${fromPennyToInt(config.amount)}`;
};

export default getContentId;
