import { Main } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';

import sendAnalyticsWithEventLabel from 'helpers/analytic/sendAnalyticsWithEventLabel';

import { ISignInData } from 'types/user/auth';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';
import { UserSingUpInfo } from 'interfaces/User/user';

export function sendClickAnalytic(customURL: string | undefined) {
    sendAnalyticsWithEventLabel({
        url: customURL || window.location.pathname,
        eventValue: 'continue',
    });
}

export const handleRequiredFields = (
    actualInfo: UserSingUpInfo | ISignInData,
    defaultInfo: UserSingUpInfo | ISignInData
) => {
    const actualDataKeys = Object.keys(actualInfo);
    const defaultDataKeys = Object.keys(defaultInfo);

    const difference = defaultDataKeys.filter((x) => !actualDataKeys.includes(x));

    if (difference.length > 0) {
        sentryCaptureEvent(new Error('Wrong fields are send'), SENTRY_APP, ERROR_LEVELS.ERROR, {
            missingFields: difference,
        });
    }
};

export function sendSuccessfulSignUpAnalytic() {
    Main.track('Lead', { isPrefix: false });
    Main.track('Registration', { isPrefix: false });
    Main.track('SIGN_UP', { isPrefix: false });
}
