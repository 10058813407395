import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { ITextProps } from 'types/pages/onboardingPages/componentsTypes';

import base from 'assets/styles/Common.module.css';
import styles from './Text.module.css';

const globalStyles = { ...styles, ...base };
const cx = classNames.bind(globalStyles);

function Text({
    globalClassNames = [],
    children,
    medium,
    small,
    bold,
    normal,
    caption,
    grey,
    centered,
    stroke,
    warning,
    overline,
    lineThrough,
    textSecondaryColor,
    bodySecond,
    cardColor,
    cardHoverColor,
    success,
    blackColor,
    borderDefaultColor,
    secondarySubdued,
    className = '',
    dataLocator,
}: ITextProps) {
    // @ts-ignore
    const classes = globalClassNames.map((item) => ({ [item]: globalStyles[item] }));

    const textClass = cx(
        'default',
        {
            medium,
            small,
            caption,
            bold,
            normal,
            grey,
            centered,
            stroke,
            warning,
            overline,
            lineThrough,
            textSecondaryColor,
            bodySecond,
            cardColor,
            cardHoverColor,
            success,
            blackColor,
            borderDefaultColor,
            secondarySubdued,
        },
        classes,
        className
    );

    return (
        <p data-locator={dataLocator} className={textClass}>
            {children}
        </p>
    );
}

export default memo(Text);
