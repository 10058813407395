import { UserGender } from 'redux/sagas/user/interfaces';

import { getBirthday } from 'helpers/onboarding/onboardingUtils';

import {
    ActivityLvlType,
    CurrentBodyType,
    FitnessLevelType,
    GoalType,
    MeasuringSystemType,
    SedentaryLifeStyleType,
    TargetBodyType,
} from 'types/onboarding/onboarding';

import { locations, zones } from './onboardingConstants';

export const defaultNumberSystem = MeasuringSystemType.IMPERIAL;
export const defaultMeasureSystem = MeasuringSystemType.IMPERIAL;
export const defaultFitnessLevel = FitnessLevelType.beginner;
export const defaultGoal = GoalType.muscle_gain;
export const defaultPreferActivity = 'walking';
export const defaultCurrentBody = CurrentBodyType.average;
export const defaultTargetBody = TargetBodyType.bulk;
export const defaultGender = UserGender.male;
export const defaultTargetZones = [zones.arms, zones.pecs];
export const defaultTall = 175;
export const defaultCurrentWeight = 82;
export const defaultTargetWeight = 85;
export const defaultAge = 35;
export const defaultDateOfBirth = getBirthday(defaultAge);
export const defaultTrainingLocations = [locations.gym];
export const defaultConsentMarketingStatus = false;
export const defaultBranchName = 'default';
export const defaultTestName = 'default';
export const defaultLanguage = 'en';
export const defaultNavigatorLanguage = 'en-US';
export const defaultSedentaryLifeStyle = SedentaryLifeStyleType.no;
export const defaultActivityLvl = ActivityLvlType.one_two_hours;
export const defaultBMI = 25;
export const barIgnoredPages = ['loader', 'email', 'email_consent', 'result', 'result_a'];
export const middleBreakpoint = 601;
export const defaultTabletWidth = 768;
export const defaultSmallDesktopWidth = 1024;
export const defaultDesktopWidth = 1366;
