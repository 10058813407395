import config from 'config';

import { deleteConfig } from './other/commonUtils';

type moveToCabinetProps = {
    token?: string;
    awsId?: string;
};

export const moveToCabinet = ({ token, awsId }: moveToCabinetProps) => {
    deleteConfig();

    const cabinetURL = new URL(config.CABINET_URL);

    cabinetURL.searchParams.append('ref', 'web2app');

    if (token) {
        cabinetURL.searchParams.append('token', token);
    }

    if (awsId) {
        cabinetURL.searchParams.append('track-id', awsId);
    }

    window.location.replace(cabinetURL.toString());
};
