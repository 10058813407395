import { INTRODUCTORY_B_FLOW_PAYMENT_ID, INTRODUCTORY_C_FLOW_PAYMENT_ID } from 'constants/payment/payments';

import { getConfigValidator, getIntroductoryFlowValidator } from '../validators/flowValidators';

export const validateFlow = ({ config, currentFlowName, setCurrentFlowName }) => {
    let status = true;

    const { payment_flow } = config;

    const checkFlow = (flowId) => payment_flow.find((item) => item.id === flowId);

    const runner = (rules) => {
        for (const rule of rules) {
            const errorMessage = '%c Flow validation error - ' + rule.message;

            if (!rule.exec(rule.args)) {
                status = false;

                // TODO: handle case if error on flow, and default flow include target page

                console.error(errorMessage, 'color:red;');
                break;
            }
        }
    };

    const runConfig = () => {
        const validator = getConfigValidator(config);

        runner(validator);
    };

    const runFlow = (flow, flowName, flowValidator) => {
        setCurrentFlowName(flowName);

        const { products } = flow;

        const validator = flowValidator(products);

        if (status) runner(validator);
    };

    runConfig();

    const isIntroductoryBPage = checkFlow(INTRODUCTORY_B_FLOW_PAYMENT_ID);
    const isIntroductoryCPage = checkFlow(INTRODUCTORY_C_FLOW_PAYMENT_ID);
    const isIntroductoryFlow = isIntroductoryBPage && isIntroductoryCPage;

    if (isIntroductoryFlow) {
        runFlow(isIntroductoryBPage, 'IntroductoryB', getIntroductoryFlowValidator);
        runFlow(isIntroductoryCPage, 'IntroductoryC', getIntroductoryFlowValidator);
    }

    return Promise.resolve({ name: `validate ${currentFlowName} flow`, status });
};
