import { Provider } from 'wikr-core-analytics';
import useI18next from 'services/i18n';
import { ERROR_LEVELS, SENTRY_ANALYTIC } from 'sentry-utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';

import { selectCountry, selectFullURL, selectUserId } from 'redux/User/selectors';
import { setFullURL, setIsAutologinComplete } from 'redux/User/actions';
import { showAppLoader } from 'redux/UiEffects/actions';
import { selectTestaniaName } from 'redux/Testania/selectors';
import { resetStore } from 'redux/rootActions';
import { initAutoLogin, setURLParams } from 'redux/Onboadring/actions';

import { DEFAULT_LTV_COEFFICIENT, LTV_COEFFICIENT_KEY } from 'constants/user';

import { initAmazonAnalytic, initHotjar } from 'services/Analytics/initAnalytics';

import { setRouteList } from '../router/helpers/helpers';
import { getUrlParamsFromURL } from '../helpers/other/commonUtils';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';
import { useSkipOnboarding } from './useSkipOnboarding';

const useInitApp = () => {
    const dispatch = useDispatch();
    const { isTranslationsLoaded } = useI18next();

    const prevCountry = useRef<string | null>(null);
    const prevAbTestName = useRef<string | null>(null);

    const country = useSelector(selectCountry);
    const abTestName = useSelector(selectTestaniaName);
    const fullURL = useSelector(selectFullURL);
    const userId = useSelector(selectUserId);

    useSkipOnboarding();

    useEffect(() => {
        const urlParamsFromURL = getUrlParamsFromURL();
        const isOneTrustAccepted = urlParamsFromURL?.['oneTrustAccepted'];
        const uuid = urlParamsFromURL?.['uuid'];
        const awsId = urlParamsFromURL?.['track-id'];

        localStorage.setItem(LTV_COEFFICIENT_KEY, String(DEFAULT_LTV_COEFFICIENT));

        if (awsId) {
            localStorage.setItem('aws_id', awsId);
        }

        const isTestaniaInit = localStorage.getItem('testania_name');

        if (isOneTrustAccepted) {
            localStorage.setItem('oneTrustAccepted', isOneTrustAccepted);
        }

        if (uuid) {
            localStorage.setItem('uuid', uuid);
        }

        if (isTranslationsLoaded && isTestaniaInit) {
            dispatch(showAppLoader(false));
        }

        if (!fullURL) {
            dispatch(setFullURL(location.href));
        }

        setAnalyticData(userId as number);

        initAmazonAnalytic();

        if (urlParamsFromURL) {
            dispatch(setURLParams(urlParamsFromURL));
        }

        if (urlParamsFromURL?.token) {
            dispatch(initAutoLogin());
        } else {
            dispatch(setIsAutologinComplete(true));
        }

        if (urlParamsFromURL?.reset) {
            // Reset app if url contains a reset param
            localStorage.clear();
            dispatch(resetStore());
            window.location.replace('/');
        }

        initHotjar();
        setRouteList();
    }, []);

    useEffect(() => {
        if (prevCountry.current !== country || prevAbTestName.current !== abTestName) {
            setAnalyticData();
            setNewPropsToRef();
        }
    }, [country, abTestName]);

    const setNewPropsToRef = () => {
        prevCountry.current = country;
        prevAbTestName.current = abTestName;
    };

    const setAnalyticData = (userId?: number) => {
        Provider.provideData({
            country: async () => await getCountry(),
            abTestName: async () => await getAbTestName(),
            clientHandlerFn: (e: Error) => sentryCaptureEvent(e, SENTRY_ANALYTIC, ERROR_LEVELS.ERROR, {}),
        });

        if (userId) {
            Provider.setUserId(Number(userId));
        }
    };

    const getCountry = async () => {
        return new Promise((resolve) => {
            resolve(country);
        });
    };

    const getAbTestName = async () => {
        return new Promise((resolve) => {
            resolve(abTestName);
        });
    };
};

export default useInitApp;
