/* eslint-disable max-lines */
import { Main } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_PAYMENT } from 'sentry-utils';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import api from 'api';

import { selectFullURL, selectUserId } from 'redux/User/selectors';
import { selectTestaniaName } from 'redux/Testania/selectors';
import {
    selectCurrency,
    selectCurrentProduct,
    selectPaymentMethod,
    selectPurchaseWithoutValue,
    selectUpgradedProduct,
} from 'redux/Payment/selectors';
import * as actionTypes from 'redux/Payment/actionTypes';
import {
    setPaymentDataAfterValidate,
    setSubscriptionId,
    validatePayment as validatePaymentAction,
} from 'redux/Payment/actions';

import { DEVICE_OS, PAYMENT_TYPES_NAME } from 'constants/payment/payments';
import { DEFAULT_ACTIVE_ANALYTICS } from 'constants/analytic/analytic';

import { getDeviceOS } from 'helpers/other/userAgentUtils';
import { cleanObject } from 'helpers/other/dataStructuresUtils';
import normalizeValidatePayload from './helpers/normalizeValidatePayload';
import normalizeData from './helpers/normalizeData';
import getValidateData from './helpers/getValidateData';
import getContentId from './helpers/getContentId';

import { ValidateResponse } from 'types/payments/validate';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';
import {
    ContentIdConfig,
    currentProductType,
    DataAfterNormalization,
    PaymentMethod,
    PurchaseAnalyticData,
    RawData,
    ValidatePayloadAfterNormalization,
} from 'interfaces/Payments/payments';
import { Currency } from '../user/interfaces';

function* getAnalyticData() {
    const currentProduct: currentProductType = yield select(selectCurrentProduct);
    const upgradedProduct: currentProductType = yield select(selectUpgradedProduct);
    const currency: Currency = yield select(selectCurrency);
    const purchaseWithoutValue: boolean = yield select(selectPurchaseWithoutValue);
    const userId: number = yield select(selectUserId);
    const testaniaName: string = yield select(selectTestaniaName);
    const fullURL: string = yield select(selectFullURL);
    const paymentMethod: PaymentMethod = yield select(selectPaymentMethod);

    const paymentMethodName: string | null = PAYMENT_TYPES_NAME[paymentMethod] || null;

    const deviceOS: string = getDeviceOS();
    const platform = deviceOS === DEVICE_OS.ANDROID || deviceOS === DEVICE_OS.IOS ? deviceOS : DEVICE_OS.ANDROID;

    const defaultAmazonPurchaseData: Record<string, any> = yield Main.getDefaultAmazonAnalyticPurchaseData();

    return {
        currentProduct,
        upgradedProduct,
        currency,
        userId,
        testaniaName,
        platform,
        fullURL,
        paymentMethodName,
        config: {
            purchaseWithoutValue,
        },
        defaultAmazonPurchaseData,
    };
}

function* validate({ payload }: ReturnType<typeof validatePaymentAction>) {
    const {
        data: { order, brand = '' }, // TODO: CHECK WHY BRAND IS UNDEFINED
        screenId,
        paymentMethod,
        analytics,
    } = payload;

    try {
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

        const validatePayload: ValidatePayloadAfterNormalization = normalizeValidatePayload({
            amount: order?.amount,
            screenId,
            paymentMethod,
        });

        const analyticData: RawData = yield call(getAnalyticData);

        const normalizedData: DataAfterNormalization = normalizeData(analyticData);
        const finalValidateMeta = getValidateData(
            normalizedData,
            validatePayload,
            order,
            brand,
            analyticData,
            Number(paymentMethod)
        );

        const postInitResponse: { data: ValidateResponse } = yield api.payment.postInit(finalValidateMeta);

        const isPendingStatus = postInitResponse.data.is_pending;

        yield put(setPaymentDataAfterValidate({ ...postInitResponse.data }));

        const contentIdConfig: ContentIdConfig = {
            amount: order.amount,
            isIntroductory: normalizedData.trial,
            introductorySubscriptionPrice: normalizedData.introductorySubscriptionPrice,
        };
        const contentId: string = getContentId(contentIdConfig);

        const options = {
            currency: normalizedData.currency,
            value: normalizedData.value,
            subscription_price: normalizedData.subscription_price,
            tariff: normalizedData.tariff,
            ab_test_name: normalizedData.ab_test_name,
            user_id: normalizedData.user_id,
            order_type: normalizedData.payment_type,
            payment: normalizedData.payment_method_name,

            price: validatePayload.amountValue,
            screen_id: validatePayload.pageUrl,

            order_id: order.order_id,
            subscription_id: order.subscription_id,

            card_type: brand,
            content_id: contentId,
            analytics,
        } as PurchaseAnalyticData;

        isPendingStatus ? sendAnalyticPurchasePending(options) : sendAnalyticPurchase(options);

        yield put(setSubscriptionId(order.subscription_id));
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
    } catch (error) {
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });

        sentryCaptureEvent(error, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, { ...payload });
    }
}

function sendAnalyticPurchase(payload: PurchaseAnalyticData) {
    Main.purchaseNew(cleanObject(payload), {
        selectedSystems: payload.analytics.length ? payload.analytics : DEFAULT_ACTIVE_ANALYTICS,
    });
}

function sendAnalyticPurchasePending(payload: PurchaseAnalyticData) {
    Main.customData('purch_pending', cleanObject(payload));
}

export const validatePayment = function* () {
    yield takeLatest(actionTypes.VALIDATE_PAYMENT, validate);
};
