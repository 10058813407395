import { useSelector } from 'react-redux';

import { selectCurrency } from 'redux/Payment/selectors';

import { isString } from 'helpers/other/dataStructuresUtils';

const useCurrencyData = () => {
    const currency = useSelector(selectCurrency);

    const currencySign = currency.sign;
    const currencyName = isString(currency) ? JSON.parse(currency).name : currency.name;

    return { currencySign, currencyName };
};

export default useCurrencyData;
