/* eslint-disable max-lines */
import { Main } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_PAYMENT } from 'sentry-utils';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { ClientSdkInstance, MessageType, SdkMessage } from '@solidgate/react-sdk';

import {
    setExclusiveOfferHasBeenOpened,
    setHideCustomPaymentButton,
    setIsApplePayAvailable,
    setIsLoadingBankCard,
    setIsPaymentSuccess,
    setLoadingStatus,
    setOpenModal,
    setPaymentErrorCode,
} from 'redux/Payment/actions';

import { BANK_CARD_CUSTOM_BUTTON_ID, SOLID_ERROR_CODES, PAYMENT_EVENT_NAMES } from 'constants/payment/payments';
import { EXCLUSIVE_OFFER_MODAL } from 'constants/payment/modals';
import { INSUFFICIENT_FUNDS_ERROR_CODE } from 'constants/errors/errors';

import { Tags } from 'services/Sentry/types';
import sentry from 'services/Sentry/SentryInstance';

import useValidatePayment from 'hooks/useValidatePayment';

import { IUsePaymentHandlers } from 'types/payments/payments';

export const usePaymentHandlers = ({
    paymentData,
    currentProduct,
    initPaymentGenerator,
    pageInfo,
    setHasPaymentError,
    setHasPaymentSuccess,
}: IUsePaymentHandlers) => {
    const dispatch = useDispatch();
    const [isProcessing, setIsProcessing] = useState(false);

    const validatePaymentHandler = useValidatePayment({ pageInfo });

    const getErrorCustomTags = (error: { code?: string; message?: string | string[] }) => {
        let customTags: Tags | undefined;

        const getErrorMessage = (message: string | string[]) => (typeof message === 'string' ? message : message[0]);

        if (error?.code) {
            customTags = [['SOLID_ERROR_CODE', error?.code]];
        }

        if (error?.message) {
            customTags = customTags
                ? [...customTags, ['SOLID_ERROR_MESSAGE', getErrorMessage(error?.message)]]
                : [['SOLID_ERROR_MESSAGE', getErrorMessage(error?.message)]];
        }

        return customTags;
    };

    const handleOnSubmit = (event: SdkMessage[MessageType.Submit]) => {
        const paymentValue = PAYMENT_EVENT_NAMES[event.entity];

        dispatch(setLoadingStatus(true));

        setIsProcessing(true);

        Main.customData('processor_form__click', { payment: paymentValue, event_label: paymentValue });
    };

    const handleOnSuccess = (data: SdkMessage[MessageType.Success]) => {
        validatePaymentHandler('bankCard', paymentData.payment_method, {
            order: {
                amount: data.order.amount as number,
                order_id: data.order.order_id as string,
                subscription_id: data.order.subscription_id as string,
            },
        });

        setHasPaymentSuccess(true);

        dispatch(setIsPaymentSuccess(true));
        dispatch(setHideCustomPaymentButton(true));

        setIsProcessing(false);
    };

    const handleOnError = (error: SdkMessage[MessageType.Error]) => {
        setIsProcessing(false);
        dispatch(setIsPaymentSuccess(false));

        const errorCode = error?.value?.code ?? '';

        if (errorCode) {
            dispatch(
                setPaymentErrorCode({
                    error_code: error.value.code,
                    product_name: currentProduct?.name,
                    currency: paymentData.currency,
                    product_price: currentProduct?.price,
                })
            );
        }

        const customTags = getErrorCustomTags(error.value);

        if (SOLID_ERROR_CODES.includes(errorCode ? errorCode : '')) {
            sentry.logError(
                error,
                SENTRY_PAYMENT,
                ERROR_LEVELS.CRITICAL,
                {
                    ...(currentProduct as object),
                    ...paymentData,
                },
                customTags
            );
        }
        const codeErrorList = [INSUFFICIENT_FUNDS_ERROR_CODE];

        if (codeErrorList.includes(`${error?.value?.code}`)) {
            setTimeout(() => {
                dispatch(setOpenModal(EXCLUSIVE_OFFER_MODAL));
                dispatch(setExclusiveOfferHasBeenOpened(true));
            }, 2000);
        }

        setTimeout(() => {
            initPaymentGenerator();
        }, 5000);
    };

    const handleOnReadyPaymentInstance = (form: ClientSdkInstance) => {
        dispatch(setHideCustomPaymentButton(false));
        dispatch(setIsLoadingBankCard(false));
        dispatch(setIsPaymentSuccess(false));

        setIsProcessing(false);

        const customPaymentButton = document.getElementById(BANK_CARD_CUSTOM_BUTTON_ID);

        if (customPaymentButton) {
            customPaymentButton.onclick = () => {
                if (isProcessing) return;

                form?.submit();
            };
        }

        Main.customData('processor_form__load', { payment: 'bank_card', event_label: 'bank_card' });
    };

    const handleOnFail = (error: SdkMessage[MessageType.Fail]) => {
        const customTags = getErrorCustomTags(error);
        const errorCode = error?.code ?? '';

        setHasPaymentError(true);

        dispatch(setHideCustomPaymentButton(true));
        dispatch(setIsPaymentSuccess(false));

        setIsProcessing(false);

        if (error.code) {
            dispatch(
                setPaymentErrorCode({
                    error_code: error.code,
                    product_name: currentProduct?.name,
                    currency: paymentData.currency,
                    product_price: currentProduct?.price,
                })
            );
        }

        if (errorCode === INSUFFICIENT_FUNDS_ERROR_CODE) {
            setTimeout(() => {
                dispatch(setOpenModal(EXCLUSIVE_OFFER_MODAL));
                dispatch(setExclusiveOfferHasBeenOpened(true));
            }, 2000);
        }

        if (SOLID_ERROR_CODES.includes(errorCode ? errorCode : '')) {
            sentry.logError(
                error,
                SENTRY_PAYMENT,
                ERROR_LEVELS.CRITICAL,
                {
                    ...currentProduct,
                    ...paymentData,
                },
                customTags
            );
        }

        setTimeout(() => {
            console.error('fail handler', error);

            dispatch(setHideCustomPaymentButton(false));

            initPaymentGenerator();
        }, 3000);
    };

    const handleOnMounted = (event: SdkMessage[MessageType.Mounted]) => {
        if (event.entity === 'applebtn' && event.type === 'mounted') {
            dispatch(setIsApplePayAvailable(true));
        }
    };

    return {
        isProcessing,
        handleOnSubmit,
        handleOnSuccess,
        handleOnError,
        handleOnFail,
        handleOnReadyPaymentInstance,
        handleOnMounted,
    };
};
