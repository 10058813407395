import { Trans } from 'react-i18next';
import React from 'react';

import { getProjectEmail } from 'helpers/other/commonUtils';

export const DEFAULT_EMAIL_ERROR_TEXT = (
    <Trans i18nKey="onboarding.email.generalErrorMessage" values={{ currentProjectEmail: getProjectEmail() }} />
);
export const INSUFFICIENT_FUNDS_ERROR_CODE = '3.02';

export const DOUBLE_PURCHASE_ERROR = 'Conflict Request';
