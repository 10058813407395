import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';

import { setIsInitTestania } from 'redux/User/actions';
import { selectRedirectPath } from 'redux/UiEffects/selectors';

import { PRELOAD_TESTANIA } from 'constants/general/defaultRoutes';

import useOnboardingDependencies from 'hooks/useOnboardingDependencies';
import useTestania from 'hooks/Testania';

import PublicRouter from './routers/public';

const Routers = () => {
    useOnboardingDependencies();
    useTestania();

    const dispatch = useDispatch();

    const history = useHistory();

    const redirectPath = useSelector(selectRedirectPath);

    useEffect(() => {
        dispatch(setIsInitTestania(true));
    }, []);

    useEffect(() => {
        if (redirectPath) {
            history.push(redirectPath);
        }
    }, [redirectPath]);

    return (
        <>
            <Switch>
                {PublicRouter.map((route, index) => (
                    <Route {...route} key={index} />
                ))}
                <Redirect to={PRELOAD_TESTANIA} />
            </Switch>
        </>
    );
};

export default Routers;
