import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { put, select, takeLatest } from 'redux-saga/effects';

import { selectCountry } from 'redux/User/selectors';
import { SET_COUNTRY } from 'redux/User/actionTypes';
import { selectMeasureSystem } from 'redux/Onboadring/selectors';
import { setMeasure } from 'redux/Onboadring/actions';

import {
    COUNTRIES_IMPERIAL_SYSTEM,
    METRIC_MEASURE_SYSTEM,
    IMPERIAL_MEASURE_SYSTEM,
} from 'constants/general/countriesData';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';

export function* setMeasureSystem() {
    try {
        const userCountry: string = yield select(selectCountry);
        const measureFromStore: string = yield select(selectMeasureSystem);

        if (measureFromStore) {
            return;
        }

        const isImperialCountryInArray: boolean = COUNTRIES_IMPERIAL_SYSTEM.some((country) => country === userCountry);

        const measureSystem = isImperialCountryInArray ? IMPERIAL_MEASURE_SYSTEM : METRIC_MEASURE_SYSTEM;

        yield put(setMeasure(measureSystem));
    } catch (error) {
        sentryCaptureEvent(error, SENTRY_APP, ERROR_LEVELS.WARNING);
    }
}
export const setDefaultMeasure = function* () {
    yield takeLatest(SET_COUNTRY, setMeasureSystem);
};
