import Solid from 'solid-payment';
import { ERROR_LEVELS, SENTRY_PAYMENT } from 'sentry-utils';
import { call, put, select, take } from 'redux-saga/effects';

import config from 'config';

import { selectLanguage, selectToken } from 'redux/User/selectors';
import { RootState } from 'redux/rootReducer';
import { selectSandbox, selectSolidInited } from 'redux/Payment/selectors';
import * as actionTypes from 'redux/Payment/actionTypes';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';

export const getToken = (state: RootState) => selectToken(state);
export const getSolidInit = (state: RootState) => selectSolidInited(state);
export const getSandbox = (state: RootState) => selectSandbox(state);
export const getLanguage = (state: RootState) => selectLanguage(state);

export function* init() {
    const language = String(localStorage.getItem('language'));
    const token = String(localStorage.getItem('token'));

    try {
        Solid.setHeaders({
            apiUrl: config.API_URL,
            apiKey: config.API_KEY,
            apiVersion: 2,
            sandbox: yield select(getSandbox),
            language,
            token,
        });

        yield put({ type: actionTypes.INIT_SOLID_PAYMENT, payload: true });
    } catch (error) {
        sentryCaptureEvent(error, SENTRY_PAYMENT, ERROR_LEVELS.ERROR);
    }
}

export function* tokenWatcher() {
    let previous: string = yield select(getToken);

    while (true) {
        const solidStatus: boolean = yield select(getSolidInit);

        yield take('*'); // listening all actions
        const next: string = yield select(getToken);

        if ((!solidStatus && next) || (next !== previous && solidStatus)) {
            previous = next;
            yield call(init);
        }
    }
}

export const initSolidPayment = function* () {
    yield tokenWatcher();
};
