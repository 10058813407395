import { Theme } from 'wikr-core-components';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { setFirstPageData, setInlineTheme, setLogoUrl } from 'redux/UiEffects/actions';
import { setSandbox } from 'redux/Payment/actions';

import { getFaviconElement } from 'helpers/other/commonUtils';

import { ThemeConfigScheme } from 'types/pages/startPages/commonTypes';

const useThemeConfiguration = (themeConfig: Record<string, ThemeConfigScheme>) => {
    const dispatch = useDispatch();

    const [theme, setTheme] = useState<Theme>();

    if (!theme) {
        const hostName = window.location.host;

        const isMirror = Object.keys(themeConfig).includes(hostName);
        const { firstPageImage, firstPageTitle, inlineStyle, themeStyle, logoUrl, faviconUrl, sandbox } =
            themeConfig?.[hostName] || themeConfig['default'];

        localStorage.setItem('isMirror', `${isMirror}`);
        const sandboxParsed = JSON.parse(sandbox);

        const faviconElement = getFaviconElement() as HTMLLinkElement;

        faviconElement.href = faviconUrl;

        setTheme(themeStyle);
        dispatch(
            setFirstPageData({
                firstPageTitle,
                firstPageImage,
            })
        );
        dispatch(setLogoUrl(logoUrl));
        dispatch(setInlineTheme(inlineStyle));
        dispatch(setSandbox(sandboxParsed));
    }

    return theme ?? themeConfig['default'].themeStyle;
};

export default useThemeConfiguration;
