import { Trans } from 'react-i18next';
import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { IButtonProps } from 'types/pages/onboardingPages/componentsTypes';
import Loader from 'components/Loader';
import LazyImage from 'components/LazyImage';

import base from 'assets/styles/Common.module.css';
import styles from './Button.module.css';

import payPallImg from './img/paypal.png';

const globalStyles = { ...styles, ...base };
const cx = classNames.bind(globalStyles);

function Button({
    globalClassNames = [],
    title,
    onClick,
    animated,
    disabled = false,
    isLoading = false,
    type = 'button',
    small,
    dataLocator,
    popupBtnAgree,
    popupBtnDisagree,
    lightBorder,
    i18nKey,
    rounded,
    isPayPal = false,
    cardHoverColor,
    contactUsBtn,
    optionLayout,
    className,
}: IButtonProps) {
    // @ts-ignore
    const classes = globalClassNames.map((item) => ({ [item]: globalStyles[item] }));
    const btnClass = cx(
        'default',
        {
            PayPal: isPayPal,
            animated,
            popupBtnAgree,
            popupBtnDisagree,
            lightBorder,
            rounded,
            small,
            cardHoverColor,
            contactUsBtn,
            optionLayout,
        },
        classes,
        className
    );

    const handleClick = () => {
        if (onClick) onClick();
    };

    return (
        <button data-locator={dataLocator} type={type} disabled={disabled} className={btnClass} onClick={handleClick}>
            {isLoading && <Loader isLoading={isLoading} />}
            {isPayPal && (
                <LazyImage
                    src={payPallImg}
                    srcset={payPallImg}
                    webp={payPallImg}
                    alt="paypal"
                    className={styles.paypalImg}
                    fullHeight={undefined}
                />
            )}
            {!isLoading && !isPayPal && (
                // span here prevents Google Translate replacing text nodes with <font>
                <span>
                    <Trans i18nKey={i18nKey}>{title}</Trans>
                </span>
            )}
        </button>
    );
}

export default memo(Button);
