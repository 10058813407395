export const HIDE_HEADER = 'UI/HIDE_HEADER';
export const SHOW_MODAL = 'UI/SHOW_MODAL';
export const DEVICE_OS_VIEW = 'UI/DEVICE_OS_VIEW';
export const SHOW_APP_LOADER = 'UI/SHOW_APP_LOADER';
export const SET_TOAST_CONFIG = 'USER/SET_TOAST_CONFIG';
export const INIT_FRONT_CHAT = 'UI/INIT_FRONT_CHAT';
export const INIT_TOAST = 'USER/INIT_TOAST';
export const SET_INLINE_THEME = 'UI/INLINE_THEME';
export const SET_LOGO_URL = 'UI/SET_LOGO_URL';
export const SET_MIRROR_CONFIG = 'UI/SET_MIRROR_CONFIG';
export const REDIRECT_TO = 'UI/REDIRECT_TO';
