import { createTransform } from 'redux-persist';

import { DEFAULT_MODAL_OPEN_STATUS_VALUES } from 'constants/payment/modals';

const blackListDefaultConfig = [
    {
        reducer: 'payment',
        state: 'isLoading',
        value: false,
    },
    {
        reducer: 'user',
        state: 'isLoading',
        value: false,
    },
    {
        reducer: 'ui_effects',
        state: 'showAppLoader',
        value: false,
    },
    {
        reducer: 'payment',
        state: 'modals',
        value: DEFAULT_MODAL_OPEN_STATUS_VALUES,
    },
    {
        reducer: 'payment',
        state: 'merchantData',
        value: null,
    },
];

export const blacklistTransform = (blackListConfig = blackListDefaultConfig) => {
    return blackListConfig.map((config) =>
        createTransform((inboundState, key) => {
            if (key !== config.reducer) return inboundState;
            else {
                return {
                    // @ts-ignore
                    ...inboundState,
                    [config.state]: config.value,
                };
            }
        })
    );
};
