import React, { createContext, ReactNode, useContext } from 'react';

import useOneTrustLoaded from 'hooks/useOneTrustLoaded';

const OneTrustContext = createContext(null);

interface OneTrustProviderProps {
    children: ReactNode;
}

export const OneTrustProvider = ({ children }: OneTrustProviderProps) => {
    const { oneTrust } = useOneTrustLoaded();

    const providerValue: any = oneTrust ? { oneTrust } : null;

    return <OneTrustContext.Provider value={providerValue}>{children}</OneTrustContext.Provider>;
};

export const useOneTrust = () => useContext(OneTrustContext);
