import { initReactI18next } from 'react-i18next';
import { useEffect, useState } from 'react';
import backend from 'i18next-xhr-backend';
import ICU from 'i18next-icu';
import i18n from 'i18next';

import { defaultLanguage } from 'constants/onboarding/defaultOnboardingData';

import { getLocalizationFromAvailable, getCurrentLocalization } from 'helpers/other/commonUtils';

const localeFromStorage = localStorage.getItem('language');

const navigatorLanguage = getLocalizationFromAvailable();
const languageFromURL = getCurrentLocalization();

const language = navigatorLanguage || languageFromURL || localeFromStorage || defaultLanguage;

localStorage.setItem('language', language);

const useI18next = () => {
    const [isTranslationsLoaded, setLoadingState] = useState(false);

    useEffect(() => {
        i18n.use(ICU)
            .use(backend)
            .use(initReactI18next)
            .init(
                {
                    // @ts-ignore
                    lng: navigatorLanguage || languageFromURL || localeFromStorage,
                    fallbackLng: [defaultLanguage],
                    ns: ['translation'],
                    preload: [defaultLanguage],
                    debug: false,
                    nsSeparator: false,
                    keySeparator: false,
                    react: {
                        useSuspense: false,
                    },
                },
                () => {
                    setLoadingState(true);
                }
            );
    }, []);

    return {
        isTranslationsLoaded,
    };
};

export default useI18next;
