export const setupInlineStyles = (styles: Record<string, string>) => {
    Object.entries(styles).forEach((property) => {
        document.documentElement.style.setProperty(property[0], property[1]);
    });
};

export const root = {
    /* colors */
    '--white-color': '#ffffff',
    '--black-color': '#000',
    '--background-color': '#1d1d1d',
    '--card-color': '#28292b',
    '--card-color-hover': '#36373a',
    '--icon-color': '#939394',
    '--text-secondary': '#bababa',
    '--stroke-color': '#e0e0e0',
    '--success-color': '#49d461',
    '--warning-color': '#feec4c',

    /* elements colors  */

    '--brand-primary': '#0045ff',
    '--brand-primary-hover': '#1847df',
    '--brand-primary-opacity-5': 'rgba(0, 69, 255, 0.5)',
    '--brand-primary-highlight': '#f2f6ff',
    '--error-color': '#F53535',
    '--error-color-highlight': '#fffafa',
    '--discount-bg-color': 'linear-gradient(90deg, #910314 0%, #f43149 52.34%, #910314 100%)',

    /* other values */

    '--default-indent': '16px',
    '--default-border-radius': '12px',
    '--small-border-radius': '8px',
    '--default-indent-double': 'calc(var(--default-indent) * 2)',
    '--font-family': '"Roboto", sans-serif',
    '--header-height': '56px',
    '--desktop-breakpoint': '1025',
    '--tablet-width': '768px',
    '--desktop-width': '1024px',
    '--max-desktop-width': '1920px',
};
