import { Image as LibImage, IImage } from 'wikr-core-components';
import React, { FC } from 'react';

const Image: FC<IImage> = ({ isForceVisible, alt, src, maxWidth, center, className, mb }) => {
    return (
        <LibImage
            isForceVisible={isForceVisible}
            alt={alt}
            src={src}
            maxWidth={maxWidth}
            center={center}
            className={className}
            mb={mb}
        />
    );
};

export { Image };
