import { AnyAction, combineReducers } from 'redux';

import UserReducer from './User/reducer';
import UiEffects from './UiEffects/reducer';
import TestaniaReducer from './Testania/reducer';
import { RESET_STORE } from './rootActionTypes';
import PaymentReducer from './Payment/reducer';
import OnboardingReducer from './Onboadring/reducer';
import ModalsReducer from './Modals/reducer';

const appReducer = combineReducers({
    user: UserReducer,
    onboarding: OnboardingReducer,
    ui_effects: UiEffects,
    payment: PaymentReducer,
    testania: TestaniaReducer,
    modals: ModalsReducer,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
    if (action.type === RESET_STORE) {
        localStorage.removeItem('isResetStore');
        // @TODO need to check this and refactor
        // @ts-ignore
        state = undefined;
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
