export const OtherPages = [
    {
        path: 'other/ThankYou',
        route: 'thank-you',
    },
    {
        path: 'other/FeedbackSummary',
        route: 'feedback-summary-fitlevel',
    },
    {
        path: 'other/FeedbackSummaryConf',
        route: 'feedback-summary-fitlevel-conf',
    },
    {
        path: 'other/RegistrationWithPassword',
        route: 'registration-with-password',
    },
];
