import { cmInFeet, cmInInch, kgInLbs, UNITS } from './constants';

import { getToFixedNumber } from 'helpers/payment/paymentUtils';

import { MeasuringSystemType, IConvertMeasureSystemValues } from 'types/onboarding/onboarding';

class Converters {
    private readonly numberSystem: MeasuringSystemType;
    private readonly needConvertResult: boolean;

    constructor(numberSystem?: MeasuringSystemType) {
        this.numberSystem = numberSystem || MeasuringSystemType.METRIC;
        this.needConvertResult = numberSystem === MeasuringSystemType.IMPERIAL;
    }

    fromKgToLbs(kg: number): number {
        return getToFixedNumber(kg * kgInLbs);
    }

    fromKgToLbsFixed(kg: number): number {
        return Number((kg * kgInLbs).toFixed(0));
    }

    fromLbsToKg(lbs: number): number {
        return lbs / kgInLbs;
    }

    fromFeetAndInchesToCentimeters(feet: string, inches: string) {
        const feetInCM = Number(feet) * cmInFeet;
        const inchesInCM = Number(inches) * cmInInch;

        return getToFixedNumber(feetInCM + inchesInCM);
    }

    getCurrentWeight(weight: number) {
        const fixedWeight = getToFixedNumber(weight);

        return this.needConvertResult ? this.fromKgToLbs(fixedWeight) : fixedWeight;
    }

    getBMI(weight: number, height: number): number {
        return Number(weight / Math.pow(height / 100, 2));
    }

    getUnitByNumberSystem(type: IConvertMeasureSystemValues) {
        return UNITS[this.numberSystem][type];
    }
}

export default Converters;
