import React from 'react';

import {
    OnboardingPages,
    OtherPages,
    PaymentPages,
    ResultPages,
    StartPages,
    UpsellPages,
} from 'router/publicRoutesMap';

import lazyWithRetry from 'helpers/lazyWithRetry';

type RouteItem = {
    path: string;
    route: string;
};

export const pathWithRegularExpression = (path: string) => `/:language([a-z]{2})?/${path}`;

export const generateRoute = (route: string, pathToFile: string) => {
    const PageComponent = lazyWithRetry(() => import(`pages/${pathToFile}`));

    return {
        path: pathWithRegularExpression(route),
        key: route,
        render: (props: any) => <PageComponent key={pathToFile} {...props} />,
    };
};

const formatRouteList = (routeList: RouteItem[]) => {
    return routeList.map((item: RouteItem) => ({ route: item.route }));
};

export const setRouteList = () => {
    window.ROUTES_LIST = {
        onboardingPages: formatRouteList(OnboardingPages),
        paymentPages: formatRouteList(PaymentPages),
        startPages: formatRouteList(StartPages),
        upsellPages: formatRouteList(UpsellPages),
        resultPages: formatRouteList(ResultPages),
        otherPages: formatRouteList(OtherPages),
    };
};
