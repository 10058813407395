export const joinClasses = (...arg: []) => {
    return [...arg].join(' ');
};

export const disableInputMoreThatMaxFieldLength = (element: HTMLInputElement) => {
    // requires element's maxLength attribute defined
    if (element.value.length > element.maxLength) {
        element.value = element.value.slice(0, element.maxLength);
    }
};

export const setAppLoader = (status: boolean) => {
    const appLoader = document.getElementById('app-loader');

    if (appLoader !== null && status) {
        appLoader.style.display = 'block';
    }

    if (appLoader !== null && !status) {
        appLoader.style.display = 'none';
    }
};

export const getWindowSize = (width = 1024) => {
    const mediaQuery = window.matchMedia(`(min-width: ${width}px)`);

    return mediaQuery.matches;
};
