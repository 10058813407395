import { InitPayPal } from 'solid-payment/src/interfaces/payment';
import Solid from 'solid-payment';
import { ERROR_LEVELS, SENTRY_PAYMENT } from 'sentry-utils';
import { takeLatest, put } from 'redux-saga/effects';

import * as actionTypes from 'redux/Payment/actionTypes';
import { setLoadingStatus, setPaymentData } from 'redux/Payment/actions';

import { PaymentData } from 'types/payments/paymentApiInterfaces';
import { ActionType } from 'types/commonInterfaces';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';

export function* init({ payload }: ActionType<InitPayPal>) {
    try {
        yield put(setLoadingStatus(true));

        const response: PaymentData = yield Solid.init('payPal', payload);

        yield put(setPaymentData(response));
    } catch (error) {
        sentryCaptureEvent(error, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, { ...payload });
    }
}

export const initPayPalSaga = function* () {
    yield takeLatest(actionTypes.INIT_PAY_PAL, init);
};
