import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const selectUser = (state: RootState) => state.user;
const selectCountry = createSelector(selectUser, (user) => user.country);
const selectName = createSelector(selectUser, (user) => user.name);
const selectEmail = createSelector(selectUser, (user) => user.email);
const selectIsLoading = createSelector(selectUser, (user) => user.isLoading);
const selectToken = createSelector(selectUser, (user) => user.token);
const selectUserId = createSelector(selectUser, (user) => user.user_id);
const selectLanguage = createSelector(selectUser, (user) => user.language);
const selectIsAutologinComplete = createSelector(selectUser, (user) => user.isAutologinComplete);
const selectDefaultAmazonAnalyticData = createSelector(selectUser, (user) => user.defaultAmazonAnalyticData);
const selectFullURL = createSelector(selectUser, (user) => user.fullURL);
const selectUserErrorInfo = createSelector(selectUser, (user) => user.userErrorInfo);
const selectIsPaid = createSelector(selectUser, (user) => user.paidStatus);
const selectPurchaseProducts = createSelector(selectUser, (user) => user.purchasedProducts);
const selectBranchForChallenge = createSelector(selectUser, (user) => user.branchForChallenge);

export {
    selectUser,
    selectCountry,
    selectName,
    selectEmail,
    selectIsLoading,
    selectUserId,
    selectToken,
    selectLanguage,
    selectIsAutologinComplete,
    selectDefaultAmazonAnalyticData,
    selectFullURL,
    selectUserErrorInfo,
    selectIsPaid,
    selectPurchaseProducts,
    selectBranchForChallenge,
};
