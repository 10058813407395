import { useDispatch } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';

import { skipOnboarding } from '../redux/Onboadring/actions';

import { isProd } from '../helpers/isProd';

export const useSkipOnboarding = () => {
    const dispatch = useDispatch();

    if (!isProd()) {
        useHotkeys('shift+o+b', () => handleSkipOnboarding());
    }

    const handleSkipOnboarding = () => {
        dispatch(
            skipOnboarding({
                reload: () => window.location.reload(),
            })
        );
    };
};
