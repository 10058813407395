import { delay, put, select, takeLatest } from 'redux-saga/effects';

import { selectToastMessages } from 'redux/UiEffects/selectors';
import { INIT_TOAST } from 'redux/UiEffects/actionTypes';
import { initToast, setToastMessages } from 'redux/UiEffects/actions';

import { IToastMessage } from 'interfaces/UiEffects/uieffects';

export default function* activateToast({ payload }: ReturnType<typeof initToast>) {
    const toastData: IToastMessage = payload ? payload : yield select(selectToastMessages);

    yield put(setToastMessages(toastData));
    yield delay(toastData.delay);
    yield put(setToastMessages({ ...payload, status: false }));
}

export const setToast = function* () {
    yield takeLatest(INIT_TOAST, activateToast);
};
