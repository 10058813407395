import { UpsellPages } from './upsellPagesMap';
import { StartPages } from './startPagesMap';
import { ResultPages } from './resultPagesMap';
import { PaymentPages } from './paymentPagesMap';
import { OtherPages } from './otherPagesMap';
import { OnboardingPages } from './onboardingPagesMap';

export const allPagesRoutes = [
    ...StartPages,
    ...OnboardingPages,
    ...PaymentPages,
    ...UpsellPages,
    ...ResultPages,
    ...OtherPages,
];
