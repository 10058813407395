import sendAnalyticsWithEventLabel from 'helpers/analytic/sendAnalyticsWithEventLabel';

export const trackHeaderClick = (eventValue: string) => {
    sendAnalyticsWithEventLabel({
        url: `${window.location.pathname}__header`,
        eventValue,
    });
};

export const trackRefundClick = () => {
    sendAnalyticsWithEventLabel({
        url: `${window.location.pathname}__link`,
        eventValue: 'refund_policy',
    });
};

export const trackPlanClick = (eventValue: string) => {
    sendAnalyticsWithEventLabel({
        url: window.location.pathname,
        eventValue,
    });
};
