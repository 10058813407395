/* eslint-disable max-lines */
import {
    DAYS_PER_WEEK,
    DEFAULT_TRIAL_PRICE,
    PAYMENT_METHOD_NAME,
    PAYMENT_TYPE_LIFETIME,
    PAYMENT_TYPE_SUBSCRIPTION,
    PRODUCT_CODES,
} from 'constants/payment/payments';

import { replaceUnderscoreToDash } from 'helpers/other/transformationUtils';
import { getSubscriptionTitle } from 'helpers/normalizer/subscription';
import { dateNormalizer } from 'helpers/normalizer/normalizer';
import sendAnalyticsWithEventLabel from 'helpers/analytic/sendAnalyticsWithEventLabel';

export const handleAnalyticData = (eventValue) => {
    sendAnalyticsWithEventLabel({
        url: window.location.pathname,
        eventValue,
    });
};

export const isLifetime = (paymentType) => {
    return paymentType === PAYMENT_TYPE_LIFETIME;
};

export const isSubscription = (paymentType) => {
    return paymentType === PAYMENT_TYPE_SUBSCRIPTION;
};

export const isVipSupport = (productCode) => {
    return productCode === PRODUCT_CODES.APP_VIP_SUPPORT;
};

export const fromPennyToInt = (penny) => {
    return penny / 100;
};

export const getPaymentNameByMethod = (paymentMethod) => {
    try {
        return PAYMENT_METHOD_NAME[paymentMethod];
    } catch (error) {
        throw new Error(error);
    }
};

export const getPriceFromCents = (price) => {
    return getToFixedNumber(Number(price) / 100);
};

export const getPriceFromCentsFixed = (price) => {
    return (Number(price) / 100).toFixed(2);
};

export const getDiscount = (oldPrice, newPrice) => {
    return getToFixedNumber(((oldPrice - newPrice) / oldPrice) * 100, 0);
};

export const getPriceDiscount = (oldPrice, newPrice) => {
    return getToFixedNumber((newPrice / oldPrice - 1) * 100 * -1, 0);
};

export const getDiscountPercent = ({ startPrice, price }) => Math.round((1 - price / startPrice) * 100);

export const getMaxElemFromArr = (arr, fieldName) => {
    return arr.reduce((prev, cur) => (prev[fieldName] > cur[fieldName] ? prev : cur));
};

export const getMinElemFromArr = (arr, fieldName) => {
    return arr.reduce((prev, cur) => (prev[fieldName] < cur[fieldName] ? prev : cur));
};

export const getDiscountForPayment = (currentProduct, pageInfo) => {
    const selectedPeriod = currentProduct?.trial;
    const currentPrice = currentProduct?.price;
    const startPrice = currentProduct?.start_price_per_state;

    if (startPrice === 0) {
        return 0;
    }

    return getDynamicallyDiscount({ products: pageInfo?.products, selectedPeriod, currentPrice });
};

export const getDynamicallyDiscount = ({ products, selectedPeriod, currentPrice }) => {
    const productsByPeriod = products.filter((product) => product.trial === selectedPeriod);
    const maxElemFromArr = getMaxElemFromArr(productsByPeriod, 'price');
    const introductoryPriceMax = maxElemFromArr?.price;

    return Math.round((1 - currentPrice / introductoryPriceMax) * 100);
};

export const getProductPaymentData = (currentProduct) => {
    const { trial, period, start_price, price } = currentProduct;

    const currentPaymentType = replaceUnderscoreToDash(dateNormalizer(trial));
    const paymentType = trial === period ? 'SEVERAL_MONTH_PLAN' : 'ONE_WEEK_PLAN';
    const paymentLabel = getSubscriptionTitle(period);

    const fullPrice = getPriceFromCents(start_price);
    const trialPrice = getPriceFromCents(price);
    const trialLabel = trialNormalizer(trial);

    return { paymentType, currentPaymentType, paymentLabel, fullPrice, trialPrice, trialLabel };
};

export const trialNormalizer = (trial) => {
    if (trial === DAYS_PER_WEEK) {
        return '7-day Trial';
    }

    return '0_null'; // fix broken build
};

export const getToFixedNumber = (number, numbersAfterComma = 2) => {
    return Number(number.toFixed(numbersAfterComma));
};

export const getIndexOfPreselectedProduct = (products) => {
    const index = products.findIndex((item) => item.is_preselected === true);

    return index < 0 ? 1 : index;
};

export const filterProducts = (productName, products) =>
    productName.reduceRight((arr, el) => {
        return [...arr, products.find((item) => item.name === el)];
    }, []);

export const getMaxDiscount = (products) => {
    const discounts = [];

    for (let i = 0; i < products.length; i++) {
        discounts.push(getDiscount(products[i].start_price, products[i].price));
    }

    const maxDiscountInArray = () => {
        return discounts.reduce(function (p, v) {
            return p > v ? p : v;
        });
    };

    return maxDiscountInArray();
};

export const getCheckoutDisclaimer = (isIntroDisclaimer, isTimerEnd, isTrialProduct, isCheckoutGolf) => {
    switch (true) {
        case isTrialProduct:
            return 'paymentFlow.checkoutFoxtrot.trialDisclaimer';

        case !isCheckoutGolf && !isTrialProduct && !isTimerEnd && isIntroDisclaimer:
            return 'paymentFlow.checkoutFoxtrot1.disclaimer';

        case !isTrialProduct && isTimerEnd && !isIntroDisclaimer:
            return 'paymentFlow.checkoutFoxtrot.disclaimerAfterTimer';

        case isCheckoutGolf && !isTimerEnd:
            return 'paymentFlow.checkoutGolf.disclaimer';

        default:
            return 'paymentFlow.checkoutFoxtrot1.disclaimer';
    }
};

export const getTrialFullPrice = (pageInfo) => {
    const oneMonthProduct = pageInfo.products.find((product) => product.period === 31 && product.trial === 31);

    return oneMonthProduct?.start_price ? getPriceFromCents(oneMonthProduct.start_price) : DEFAULT_TRIAL_PRICE;
};
