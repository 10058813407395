import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { selectLogoUrl } from 'redux/UiEffects/selectors';

import Title from 'components/Title';

import styles from './Logo.module.css';

import { ILogoProps } from './types';

const cx = classNames.bind(styles);

function Logo({ onlyLogo, whiteTheme, footerLogo, smallLogo = false }: ILogoProps) {
    const logoClass = cx({
        logo: true,
        whiteTheme,
    });

    const logoImgStyles = cx({
        logoImgHeader: !footerLogo,
        logoImgFooter: footerLogo,
        smallLogo: smallLogo,
    });

    const textStyle = cx({
        smallText: smallLogo,
    });

    const logoUrl = useSelector(selectLogoUrl);

    return (
        <div className={logoClass}>
            <img src={logoUrl} alt="logo" className={logoImgStyles} />
            {!onlyLogo && (
                <div>
                    <Title h5 className={textStyle}>
                        <Trans i18nKey="logoTitle">Muscle {!footerLogo && <br />}Booster</Trans>
                    </Title>
                </div>
            )}
        </div>
    );
}

export default memo(Logo);
