import LazyLoad, { forceVisible } from 'react-lazyload';
import React, { useEffect, memo } from 'react';
import classNames from 'classnames/bind';

import { ILazyImageProps } from 'types/pages/onboardingPages/componentsTypes';

import base from 'assets/styles/Common.module.css';
import styles from './LazyImage.module.css';

const globalStyles = { ...styles, ...base };
const cx = classNames.bind(globalStyles);

function LazyImage({
    globalClassNames = [],
    fullHeight,
    type = 'PICTURE',
    src,
    alt,
    srcset,
    webp,
    isForceVisible = false,
    className = '',
}: ILazyImageProps) {
    // @ts-ignore
    const classes = globalClassNames.map((item) => ({ [item]: globalStyles[item] }));

    const lazyImageClass = cx('lazyLoadWrapper', { fullHeight, classes }, className);

    useEffect(() => {
        if (isForceVisible) forceVisible();
    }, [isForceVisible]);

    return (
        <div className={lazyImageClass}>
            <LazyLoad>
                {type === 'ICON' ? (
                    <img src={src} alt={alt} className={styles.image} />
                ) : (
                    <picture>
                        <source srcSet={webp} type="image/webp" />
                        <img src={src} srcSet={`${srcset} 2x`} alt={alt} className={styles.image} />
                    </picture>
                )}
            </LazyLoad>
        </div>
    );
}

export default memo(LazyImage);
