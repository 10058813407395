import { fromPennyToInt, getPaymentNameByMethod } from 'helpers/payment/paymentUtils';
import { deleteSlash } from 'helpers/other/transformationUtils';

import {
    PaymentMethod,
    ValidatePayloadAfterNormalization,
    ValidatePayloadBeforeNormalization,
} from 'interfaces/Payments/payments';

const normalizeValidatePayload = (
    beforeNormalization: ValidatePayloadBeforeNormalization
): ValidatePayloadAfterNormalization => {
    const amountValue: number = fromPennyToInt(beforeNormalization.amount);
    const pageUrl: string = deleteSlash(beforeNormalization.screenId);
    const paymentMethodName: PaymentMethod = getPaymentNameByMethod(beforeNormalization.paymentMethod);

    return {
        amountValue,
        pageUrl,
        paymentMethodName,
    };
};

export default normalizeValidatePayload;
