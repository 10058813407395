import { IConvertUnit } from './types';

export const cmInInch = 2.54;

export const cmInFeet = 30.48;

export const kgInLbs = 2.2046;

export const feetInInch = 12;

export const UNITS: IConvertUnit = {
    IMPERIAL: {
        weight: 'lbs',
        height: 'in',
        volume: 'GAL',
    },
    METRIC: {
        weight: 'kg',
        height: 'cm',
        volume: 'L',
    },
};
