import { IGetDiff } from './types';

import dayjs from 'dayjs';

class DateObj {
    addDaysToDate(days: number, date: dayjs.Dayjs): string {
        return dayjs(date, 'DD-MM-YYYY').add(days, 'days').format('DD MMM YYYY HH:mm');
    }

    getTime() {
        return dayjs();
    }

    getDiff(fromDate: dayjs.Dayjs, toDate: string): IGetDiff {
        const dateCurr = dayjs(fromDate);
        const dateTo = dayjs(toDate);
        const diff = dateTo.diff(dateCurr);
        const differentMinSec = dayjs(diff);

        return { time_to: differentMinSec, seconds: diff };
    }

    addMinutes(addMin: number): Date {
        // it will add 10 mins in the current time and will give time in 03:35 PM format; can use m or minutes
        return dayjs().add(addMin, 'minutes').toDate();
    }
}

export default new DateObj();
