import { PAYMENT_TYPES_CAPITALIZED } from 'constants/payment/payments';

import { PaymentMethod } from 'interfaces/Payments/payments';

export const useActiveOption = (activeOption: PaymentMethod) => {
    const isApplePayOption = activeOption === PAYMENT_TYPES_CAPITALIZED.APPLE_PAY;
    const isCreditCardOption = activeOption === PAYMENT_TYPES_CAPITALIZED.BANK_CARD;
    const isPayPalOption = activeOption === PAYMENT_TYPES_CAPITALIZED.PAYPAL;
    const isOneClickPaymentOption = activeOption === PAYMENT_TYPES_CAPITALIZED.ONE_CLICK_PAYMENT;

    return {
        isApplePayOption,
        isPayPalOption,
        isCreditCardOption,
        isOneClickPaymentOption,
    };
};
