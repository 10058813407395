import { Trans } from 'react-i18next';
import React, { useState } from 'react';

import { useNetworkStatus } from 'hooks';

import { Box, Button, Text } from 'libComponents';
import Modal from 'components/Modal';

import styles from './NoConnectionModal.module.css';

const NoConnectionModal = () => {
    const isOnline = useNetworkStatus();
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    };

    return (
        <Modal isOpen={!isOnline} withClose={false} centeredModal>
            <Box paddingTop={8} paddingBottom={8}>
                <span
                    role="img"
                    aria-label="network"
                    style={{ fontSize: '52px', display: 'block', textAlign: 'center', marginBottom: '20px' }}
                >
                    🌐
                </span>
                <Text type="h5" center color="text-main" mb={12}>
                    <Trans i18nKey="networkErrorModal.title" />
                </Text>
                <Text center mb={32}>
                    <Trans i18nKey="networkErrorModal.subtitle" />
                </Text>
                <Button
                    backgroundColor="surface-default"
                    isLoading={isLoading}
                    onClick={handleClick}
                    className={styles.customButton}
                    hoverColor="surface-hovered"
                >
                    <Trans i18nKey="basics.tryAgain" />
                </Button>
            </Box>
        </Modal>
    );
};

export default NoConnectionModal;
