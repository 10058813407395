export const isEmpty = (value: any) => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
};

export const isString = (value: any) => {
    if (value === undefined || value === null) return false;

    return typeof value.valueOf() === 'string';
};

export const cleanObject = <T extends Record<string, any>>(object: T): T => {
    const cleanedObj = { ...object };

    for (const propName in cleanedObj) {
        if (cleanedObj[propName] === null || cleanedObj[propName] === undefined) {
            delete cleanedObj[propName];
        }
    }

    return cleanedObj;
};
