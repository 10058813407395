import { IToastMessage } from 'interfaces/UiEffects/uieffects';

export const DEFAULT_DELAY = 5000;

export const GENERAL_ERROR_TOAST_MESSAGE: IToastMessage = {
    message: 'Something went wrong',
    type: 'error',
    delay: DEFAULT_DELAY,
    status: true,
};

export const PRIVACY_CHECKBOX_ERROR_TOAST_MESSAGE: IToastMessage = {
    message: 'startFlow.agreement.warning',
    type: 'error',
    delay: DEFAULT_DELAY,
    status: true,
};
