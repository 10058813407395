import * as actionTypes from './actionTypes';

import { setAppLoader } from 'helpers/styles/stylesUtils';
import { getDeviceOS } from 'helpers/other/userAgentUtils';

import { MirrorConfig } from 'types/pages/startPages/commonTypes';

import { IToastMessage } from 'interfaces/UiEffects/uieffects';

export function hideHeader(status: boolean) {
    return {
        type: actionTypes.HIDE_HEADER,
        payload: status,
    } as const;
}

export function showModal(status: boolean) {
    return {
        type: actionTypes.SHOW_MODAL,
        payload: status,
    } as const;
}

export function setDeviceOSView() {
    return {
        type: actionTypes.DEVICE_OS_VIEW,
        payload: getDeviceOS(),
    } as const;
}

export function showAppLoader(status: boolean) {
    setAppLoader(status);

    return {
        type: actionTypes.SHOW_APP_LOADER,
        payload: status,
    } as const;
}

export function setToastMessages(payload: IToastMessage) {
    return {
        type: actionTypes.SET_TOAST_CONFIG,
        payload: payload,
    } as const;
}

export function initToast(payload: IToastMessage) {
    return {
        type: actionTypes.INIT_TOAST,
        payload: payload,
    } as const;
}

export function initFrontChat(status: boolean) {
    return {
        type: actionTypes.INIT_FRONT_CHAT,
        payload: status,
    } as const;
}

export function setInlineTheme(theme: object) {
    return {
        type: actionTypes.SET_INLINE_THEME,
        payload: theme,
    } as const;
}

export function setFirstPageData(payload: MirrorConfig) {
    return {
        type: actionTypes.SET_MIRROR_CONFIG,
        payload: payload,
    } as const;
}

export function setLogoUrl(url: string) {
    return {
        type: actionTypes.SET_LOGO_URL,
        payload: url,
    } as const;
}

export function redirectTo(path: string) {
    return {
        type: actionTypes.REDIRECT_TO,
        payload: path,
    } as const;
}
