import {
    checkLtv,
    checkPreselectedField,
    checkProductPeriods,
    checkProductsCode,
    checkProductsDividedByTwo,
    checkValuePairsIdentityByFieldName,
    getArrayFromObjectByFieldName,
} from 'helpers/payment/PaymentFlow/utils';

import { checkConfig, checkPagesId } from './configValidator';

export const getConfigValidator = (config) => {
    return [
        { exec: checkPagesId, args: config, message: 'INVALID PAGE ID' },
        { exec: checkConfig, args: config, message: 'INVALID CONFIG' },
    ];
};

export const getIntroductoryFlowValidator = (products) => {
    const arrOfPeriods = getArrayFromObjectByFieldName(products, 'period');

    const uniquePeriods = [...new Set(arrOfPeriods)];

    return [
        { exec: checkProductsDividedByTwo, args: { products }, message: 'PRODUCTS DIVIDED BY TWO' },
        {
            exec: checkValuePairsIdentityByFieldName,
            args: { products, fieldName: 'trial' },
            message: 'TRIAL',
        },
        { exec: checkPreselectedField, args: { products, withImportantOrder: true }, message: 'PRESELECTED FIELD' },
        { exec: checkProductPeriods, args: { periodsArr: uniquePeriods }, message: 'PERIOD' },
        { exec: checkLtv, args: { products }, message: 'LTV' },
    ];
};

export const getUpsellValidator = (products) => {
    return [
        {
            exec: checkProductsCode,
            args: { products },
            message: 'PRODUCTS CODE',
        },
    ];
};
