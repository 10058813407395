import { IImage } from 'wikr-core-components';
import React, { FC } from 'react';

import { Image } from 'libComponents';

import logo from 'assets/img/fullLogo.webp';

const Logo: FC<IImage> = ({ alt = 'logo', src = logo, maxWidth = 100, center, className, mb }) => {
    return (
        <Image
            isForceVisible={true}
            alt={alt}
            src={src}
            maxWidth={maxWidth}
            center={center}
            className={className}
            mb={mb}
        />
    );
};

export { Logo };
