import { InitPaymentGenerator } from 'solid-payment/src/interfaces/payment';
import Solid from 'solid-payment';
import { SENTRY_PAYMENT, ERROR_LEVELS } from 'sentry-utils';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { InitConfig } from '@solidgate/client-sdk-loader';

import { selectCurrentProduct } from 'redux/Payment/selectors';
import * as actionTypes from 'redux/Payment/actionTypes';
import { setLoadingStatus, setMerchantData } from 'redux/Payment/actions';
import { ModalName } from 'redux/Modals/types';
import { openModal } from 'redux/Modals/actions';

import { DOUBLE_PURCHASE_ERROR } from 'constants/errors/errors';

import sentry from 'services/Sentry/SentryInstance';

import { CurrentProduct } from 'types/payments/payments';
import { ActionType } from 'types/commonInterfaces';

import { init as initSolid } from './initSolid';

export function* init({ payload }: ActionType<InitPaymentGenerator>) {
    try {
        yield call(initSolid);
        yield put(setLoadingStatus(true));
        const response: InitConfig['merchantData'] = yield Solid.init('paymentGenerator', payload);

        // ignore since the Solid payment library returns errors in the success response
        // @ts-ignore
        if (response?.message === DOUBLE_PURCHASE_ERROR) {
            const currentProduct: CurrentProduct = yield select(selectCurrentProduct);

            yield put(
                openModal(ModalName.doublePurchasedAlert, {
                    productTitle: 'Muscle Booster',
                    payment_type: currentProduct.payment_type,
                })
            );
        }

        yield put(setLoadingStatus(false));
        yield put(setMerchantData(response));
    } catch (e) {
        console.error('Init payment error', e);

        sentry.logError(e, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, {
            ...payload,
        });
    }
}

export const initPaymentGenerator = function* () {
    yield takeLatest(actionTypes.INIT_PAYMENT_GENERATOR, init);
};
