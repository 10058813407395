import * as actionTypes from './actionTypes';

import { ModalName, ModalPropsType } from './types';

export const openModal = <T extends ModalName>(modalId: T, modalProps?: ModalPropsType[T]) => {
    return {
        type: actionTypes.OPEN_MODAL,
        payload: { modalId, modalProps },
    } as const;
};

export const closeModal = () => {
    return {
        type: actionTypes.CLOSE_MODAL,
    } as const;
};
