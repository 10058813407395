import { UnitInput as LibSingleUnitInput, IUnitInputSingle } from 'wikr-core-components';
import React, { FC } from 'react';

import { translate } from 'helpers/utils';

const SingleUnitInput: FC<IUnitInputSingle> = ({
    placeholder,
    value,
    unit,
    hint,
    showHint,
    dataLocator,
    filled,
    onChange,
    onKeyDown,
    inputRef,
    hintDataLocator,
}) => {
    return (
        <LibSingleUnitInput
            placeholder={placeholder}
            value={value}
            // @ts-ignore
            unit={translate(unit)}
            hint={hint}
            showHint={showHint}
            dataLocator={dataLocator}
            filled={filled}
            onChange={onChange}
            onKeyDown={onKeyDown}
            inputRef={inputRef}
            hintDataLocator={hintDataLocator}
        />
    );
};

export { SingleUnitInput };
