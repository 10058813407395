/* eslint-disable max-lines */
export const DEFAULT_FLOW = {
    branch_name: 'default_client',
    flow_name: 'menscoach_default_default_client',
    expire: 1633705410444,
    start_page: {
        id: 'index',
        name: 'index',
        config: {},
    },
    onboarding_flow: [
        {
            id: 'ob_social_proof',
            name: 'ob_social_proof',
            config: {},
        },
        {
            id: 'goal',
            name: 'goal',
            config: {},
        },
        {
            id: 'current_body_type',
            name: 'current_body_type',
            config: {},
        },
        {
            id: 'target_body_type',
            name: 'target_body_type',
            config: {},
        },
        {
            id: 'motivation',
            name: 'motivation',
            config: {},
        },
        {
            id: 'target_zones',
            name: 'target_zones',
            config: {},
        },
        {
            id: 'perfect_weight_over',
            name: 'perfect_weight_over',
            config: {},
        },
        {
            id: 'ob_tags',
            name: 'ob_tags',
            config: {},
        },
        {
            id: 'fitness_level',
            name: 'fitness_level',
            config: {},
        },
        {
            id: 'pushups',
            name: 'pushups',
            config: {},
        },
        {
            id: 'lifestyle',
            name: 'lifestyle',
            config: {},
        },
        {
            id: 'walk_daily',
            name: 'walk_daily',
            config: {},
        },
        {
            id: 'nutr_habbit_feel',
            name: 'nutr_habbit_feel',
            config: {},
        },
        {
            id: 'active_habit_slip',
            name: 'active_habit_slip',
            config: {},
        },
        {
            id: 'nutr_habbit_water',
            name: 'nutr_habbit_water',
            config: {},
        },
        {
            id: 'workout_location',
            name: 'workout_location',
            config: {},
        },
        {
            id: 'height_a',
            name: 'height_a',
            config: {},
        },
        {
            id: 'weight_a',
            name: 'weight_a',
            config: {},
        },
        {
            id: 'target_weight_a',
            name: 'target_weight_a',
            config: {},
        },
        {
            id: 'age_a',
            name: 'age_a',
            config: {},
        },
        {
            id: 'feedback_summary_fitlevel',
            name: 'feedback_summary_fitlevel',
            config: {},
        },
        {
            id: 'loader_a',
            name: 'loader_a',
            config: {},
        },
        {
            id: 'email_b',
            name: 'email_b',
            config: {},
        },
        {
            id: 'email_consent_a',
            name: 'email_consent_a',
            config: {},
        },
        {
            id: 'result_a',
            name: 'result_a',
            config: {},
        },
    ],
    payment_flow: [
        {
            id: 'upsell_2',
            name: 'upsell_2',
            parent_id: 'payment_g1_foxtrot1',
            is_paid: true,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd',
                    price: 298,
                    name: 'fat_burning_gd__weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'fat_burning_gd__weight_loss_gd',
                    price_per_state: 699,
                    start_price_per_state: 1,
                    is_preselected: true,
                    start_price: 2000,
                    product_state: 'per_day',
                    ltv: 215,
                    processor: 'solid',
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd',
                    price: 199,
                    name: 'weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'weight_loss_gd',
                    price_per_state: 499,
                    start_price_per_state: 1,
                    is_preselected: false,
                    start_price: 1000,
                    product_state: 'per_day',
                    ltv: 144,
                    processor: 'solid',
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd',
                    price: 199,
                    name: 'fat_burning_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'fat_burning_gd',
                    price_per_state: 499,
                    start_price_per_state: 1,
                    is_preselected: false,
                    start_price: 1000,
                    product_state: 'per_day',
                    ltv: 144,
                    processor: 'solid',
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd_full',
                    price: 2000,
                    name: 'fat_burning_gd__weight_loss_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'fat_burning_gd__weight_loss_gd',
                    price_per_state: 0,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 2000,
                    product_state: 'per_day',
                    ltv: 1445,
                    processor: 'solid',
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd_full',
                    price: 1000,
                    name: 'weight_loss_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'weight_loss_gd',
                    price_per_state: 0,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 1000,
                    product_state: 'per_day',
                    ltv: 723,
                    processor: 'solid',
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd_full',
                    price: 1000,
                    name: 'fat_burning_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'fat_burning_gd',
                    price_per_state: 0,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 1000,
                    product_state: 'per_day',
                    ltv: 723,
                    processor: 'solid',
                },
            ],
            config: {},
        },
        {
            id: 'upsell_2',
            name: 'upsell_2',
            parent_id: 'offer_a1_foxtrot1',
            is_paid: true,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd',
                    price: 298,
                    name: 'fat_burning_gd__weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'fat_burning_gd__weight_loss_gd',
                    price_per_state: 699,
                    start_price_per_state: 1,
                    is_preselected: true,
                    start_price: 2000,
                    product_state: 'per_day',
                    ltv: 215,
                    processor: 'solid',
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd',
                    price: 199,
                    name: 'weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'weight_loss_gd',
                    price_per_state: 499,
                    start_price_per_state: 1,
                    is_preselected: false,
                    start_price: 1000,
                    product_state: 'per_day',
                    ltv: 144,
                    processor: 'solid',
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd',
                    price: 199,
                    name: 'fat_burning_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'fat_burning_gd',
                    price_per_state: 499,
                    start_price_per_state: 1,
                    is_preselected: false,
                    start_price: 1000,
                    product_state: 'per_day',
                    ltv: 144,
                    processor: 'solid',
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd_full',
                    price: 2000,
                    name: 'fat_burning_gd__weight_loss_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'fat_burning_gd__weight_loss_gd',
                    price_per_state: 0,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 2000,
                    product_state: 'per_day',
                    ltv: 1445,
                    processor: 'solid',
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd_full',
                    price: 1000,
                    name: 'weight_loss_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'weight_loss_gd',
                    price_per_state: 0,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 1000,
                    product_state: 'per_day',
                    ltv: 723,
                    processor: 'solid',
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd_full',
                    price: 1000,
                    name: 'fat_burning_gd_full',
                    trial: 0,
                    period: 1,
                    product_code: 'fat_burning_gd',
                    price_per_state: 0,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 1000,
                    product_state: 'per_day',
                    ltv: 723,
                    processor: 'solid',
                },
            ],
            config: {},
        },
        {
            id: 'offer_a1_foxtrot1',
            name: 'offer_a1_foxtrot1_p4',
            parent_id: 'payment_g1_foxtrot1',
            is_paid: false,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: 'cb3b7a2a-ee75-4c94-a6da-788a6148f672',
                    price: 5499,
                    name: 'One-year intro (54.99) + One-year (99.99)',
                    trial: 365,
                    period: 365,
                    product_code: 'main_plan',
                    price_per_state: 15,
                    start_price_per_state: 27,
                    is_preselected: false,
                    start_price: 9999,
                    product_state: 'per_week',
                    ltv: 3974,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: 'f6b6031c-d69d-4232-bda4-f9f6144c1736',
                    price: 1399,
                    name: 'One-month intro (13.99) + One-month (29.84)',
                    trial: 31,
                    period: 31,
                    product_code: 'main_plan',
                    price_per_state: 45,
                    start_price_per_state: 100,
                    is_preselected: true,
                    start_price: 2984,
                    product_state: 'per_week',
                    ltv: 3448,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: '9db14fb3-f224-41f6-9cc3-fa6e0d1be0c7',
                    price: 2199,
                    name: 'Three-month intro (21.99) + Three-month (44.76)',
                    trial: 90,
                    period: 90,
                    product_code: 'main_plan',
                    price_per_state: 24,
                    start_price_per_state: 50,
                    is_preselected: false,
                    start_price: 4476,
                    product_state: 'per_week',
                    ltv: 3174,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: 'c02966a2-5d79-4612-94dc-4cf6532b4b46',
                    price: 9999,
                    name: 'One-year intro (99.99) + One-year (99.99)',
                    trial: 365,
                    period: 365,
                    product_code: 'main_plan',
                    price_per_state: 27,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 9999,
                    product_state: 'per_week',
                    ltv: 7225,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: '4955828c-1691-4c50-825f-01f89d3c7d8e',
                    price: 2984,
                    name: 'One-month intro (29.84) + One-month (29.84)',
                    trial: 31,
                    period: 31,
                    product_code: 'main_plan',
                    price_per_state: 100,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 2984,
                    product_state: 'per_week',
                    ltv: 6253,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: '31caa3cb-26fe-41a8-9921-3461b2c8c91e',
                    price: 4476,
                    name: 'Three-month intro (44.76) + Three-month (44.76)',
                    trial: 90,
                    period: 90,
                    product_code: 'main_plan',
                    price_per_state: 50,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 4476,
                    product_state: 'per_week',
                    ltv: 4852,
                    processor: 'solid',
                },
            ],
            config: {},
        },
        {
            id: 'payment_g1_foxtrot1',
            name: 'payment_g1_foxtrot1_p4',
            parent_id: null,
            is_paid: null,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: 'f31b9c0c-ab1c-4d44-a290-be6e636b699a',
                    price: 6999,
                    name: 'One-year intro (69.99) + One-year (99.99)',
                    trial: 365,
                    period: 365,
                    product_code: 'main_plan',
                    price_per_state: 19,
                    start_price_per_state: 27,
                    is_preselected: false,
                    start_price: 9999,
                    product_state: 'per_day',
                    ltv: 5058,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: '226e51e5-66a9-4c2d-bc7c-afb88c0814c7',
                    price: 1999,
                    name: 'One-month intro (19.99) + One-month (29.84)',
                    trial: 31,
                    period: 31,
                    product_code: 'main_plan',
                    price_per_state: 64,
                    start_price_per_state: 100,
                    is_preselected: true,
                    start_price: 2984,
                    product_state: 'per_day',
                    ltv: 4895,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: 'd1b582ce-3f21-4620-a833-a98da465a93f',
                    price: 2999,
                    name: 'Three-month intro (29.99) + Three-month (44.76)',
                    trial: 90,
                    period: 90,
                    product_code: 'main_plan',
                    price_per_state: 33,
                    start_price_per_state: 50,
                    is_preselected: false,
                    start_price: 4476,
                    product_state: 'per_day',
                    ltv: 4011,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: 'c02966a2-5d79-4612-94dc-4cf6532b4b46',
                    price: 9999,
                    name: 'One-year intro (99.99) + One-year (99.99)',
                    trial: 365,
                    period: 365,
                    product_code: 'main_plan',
                    price_per_state: 27,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 9999,
                    product_state: 'per_day',
                    ltv: 7225,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: '4955828c-1691-4c50-825f-01f89d3c7d8e',
                    price: 2984,
                    name: 'One-month intro (29.84) + One-month (29.84)',
                    trial: 31,
                    period: 31,
                    product_code: 'main_plan',
                    price_per_state: 100,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 2984,
                    product_state: 'per_day',
                    ltv: 6253,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: '31caa3cb-26fe-41a8-9921-3461b2c8c91e',
                    price: 4476,
                    name: 'Three-month intro (44.76) + Three-month (44.76)',
                    trial: 90,
                    period: 90,
                    product_code: 'main_plan',
                    price_per_state: 50,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 4476,
                    product_state: 'per_day',
                    ltv: 4852,
                    processor: 'solid',
                },
            ],
            config: {
                analytics: 'amazon,ga,snapchat,pinterest,twitter,tiktok',
            },
        },
    ],
};
