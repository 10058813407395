import {
    DAYS_PER_WEEK,
    DAYS_PER_MONTH,
    DAYS_PER_TWO_MONTH,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_FOUR_MONTH,
    DAYS_PER_FIVE_MONTH,
    DAYS_PER_SIX_MONTH,
    DAYS_PER_SEVEN_MONTH,
    DAYS_PER_EIGHT_MONTH,
    DAYS_PER_NINE_MONTH,
    DAYS_PER_TEN_MONTH,
    DAYS_PER_ELEVEN_MONTH,
    DAYS_PER_YEAR,
} from 'constants/product/periods';

export const dateNormalizer = (days: number): string => {
    return `${dateNormalizerWithoutPlan(days)} plan`;
};

export const dateNormalizerWithoutPlan = (days: number): string => {
    switch (days) {
        case DAYS_PER_WEEK:
            return '1_week';

        case DAYS_PER_MONTH:
            return '1_month';

        case DAYS_PER_TWO_MONTH:
            return '2_month';

        case DAYS_PER_THREE_MONTH:
            return '3_month';

        case DAYS_PER_FOUR_MONTH:
            return '4_month';

        case DAYS_PER_FIVE_MONTH:
            return '5_month';

        case DAYS_PER_SIX_MONTH:
            return '6_month';

        case DAYS_PER_SEVEN_MONTH:
            return '7_month';

        case DAYS_PER_EIGHT_MONTH:
            return '8_month';

        case DAYS_PER_NINE_MONTH:
            return '9_month';

        case DAYS_PER_TEN_MONTH:
            return '10_month';

        case DAYS_PER_ELEVEN_MONTH:
            return '11_month';

        case DAYS_PER_YEAR:
            return '1_year';

        default:
            return '0_null';
    }
};

export const getUsersRefundByPeriod = (days?: number) => {
    switch (days) {
        case DAYS_PER_MONTH:
            return '1.3';

        case DAYS_PER_THREE_MONTH:
            return '2.7';

        default:
            return '2.3';
    }
};

export const getUsersSubByPeriod = (days?: number) => {
    switch (days) {
        case DAYS_PER_MONTH:
            return '5080';

        case DAYS_PER_THREE_MONTH:
            return '2932';

        default:
            return '842';
    }
};
