import { ThemeProvider } from 'wikr-core-components';
import { compose } from 'redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import React from 'react';

import { useInitApp, useThemeConfiguration } from 'hooks';

import { OneTrustProvider } from './services/OneTrust';
import Routers from './router';
import NoConnectionModal from './modals/NoConnectionModal';
import ModalManager from './modals/ModalManager';
import mirrorConfig from './mirrorConfig.json';

const App = () => {
    useInitApp();
    const theme = useThemeConfiguration(mirrorConfig);

    return (
        <OneTrustProvider>
            <ThemeProvider theme={theme}>
                <NoConnectionModal />
                <ModalManager />
                <Router>
                    <React.Suspense fallback={<div style={{ color: 'red' }} />}>
                        <Routers />
                    </React.Suspense>
                </Router>
            </ThemeProvider>
        </OneTrustProvider>
    );
};

export default compose(withTranslation())(App);
