export const EMAIL_MATCH_REGEXP = /^[\w+.\-]+(?:\.[\w+.\-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/;

export const PASSWORD_MATCH_REGEXP = /^[a-zA-Z\d$#@!%^&*?]{6,30}$/;

export const VALIDATION_CONFIG_REGEXP = /^[A-Za-z0-9_]+$/;

export const FULLNAME_REGEXP = /^[\S\s]+(\s[\S\s]+)+$/;

export const FULLNAME_REGEXP_WITH_MIN_SYMBOLS = /^[\S\s]{2,}(\s[\S\s]{2,})+$/;

export const ZIP_CODE_REGEXP = /^\d{5}(-\d{4})?$/;
