import { defaultBranchName } from 'constants/onboarding/defaultOnboardingData';

import Converters from 'helpers/converters/converters';

import { ICalculateProgressSimpleArgs } from './types';

import { ValueWithMeasuringSystem } from 'interfaces/Onboarding/onboarding';

const convert = new Converters();

interface IMap {
    [key: string]: string;
}

export const getBirthday = (fullYears: number): string => {
    const currentYear = new Date().getFullYear();

    return `${currentYear - fullYears}-01-01`;
};

// format of input date 'yyyy-01-01'
export const getAgeFromBirthday = (birthday: string): number => {
    if (birthday) {
        const today = new Date();
        const todayYear = today.getFullYear();
        const yearOfBirth = birthday.split('-')[0];

        return todayYear - Number(yearOfBirth);
    } else return 0;
};

export const getKgWeight = (data: ValueWithMeasuringSystem): number => {
    const { unit, value } = data;

    return unit === 'lbs' ? +convert.fromLbsToKg(value) : +value;
};

export const getNumberSystem = (unit: string): string => {
    const mapItems: IMap = {
        lbs: 'IMPERIAL',
        in: 'IMPERIAL',
        cm: 'METRIC',
        kg: 'METRIC',
    };

    return mapItems[unit] || 'IMPERIAL';
};

export const generateRandomIntBetween = (min: number, max: number): number => {
    return Math.round(Math.random() * (max - min) + min);
};

export const calculateProgressSimple = ({ setProgress, onComplete }: ICalculateProgressSimpleArgs) => {
    let currentProgress = 0;
    const speed = 150;
    const timerId = setTimeout(frame, speed);

    async function frame() {
        if (currentProgress === 100) {
            clearInterval(timerId);

            currentProgress = 0;

            onComplete && onComplete();
        } else {
            setProgress(++currentProgress);
            setTimeout(frame, speed);
        }
    }

    return timerId;
};

export const calculateProgressWithSameSpeed = ({ setProgress, onComplete }: ICalculateProgressSimpleArgs) => {
    const DEFAULT_SPEED = 90;
    const FINAL_SPEED = 500;

    let currentProgress = 0;

    const timerId = setTimeout(frame, DEFAULT_SPEED);

    async function frame() {
        if (currentProgress === 100) {
            clearInterval(timerId);

            currentProgress = 0;

            onComplete && onComplete();
        } else {
            const speed = currentProgress < 99 ? DEFAULT_SPEED : FINAL_SPEED;

            setProgress(Math.round(++currentProgress));

            setTimeout(frame, speed);
        }
    }

    return timerId;
};

export const getBranchName = (urlParams: Record<string, string>, branchForChallenge?: string) => {
    if (!urlParams?.['branch-name']) return defaultBranchName;
    if (branchForChallenge) return branchForChallenge;

    return urlParams?.['branch-name'];
};
