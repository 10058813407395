import { Box as LibBox, IBox } from 'wikr-core-components';
import React, { FC } from 'react';

const Box: FC<IBox> = ({
    withShadow,
    borderRadius,
    mb,
    backgroundColor,
    borderColor,
    paddingX,
    paddingTop,
    paddingBottom,
    className,
    children,
}) => {
    return (
        <LibBox
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            borderRadius={borderRadius}
            /* eslint-disable-next-line react/no-children-prop */
            children={children}
            className={className}
            mb={mb}
            paddingBottom={paddingBottom}
            paddingTop={paddingTop}
            paddingX={paddingX}
            withShadow={withShadow}
        />
    );
};

export { Box };
