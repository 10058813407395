import { ERROR_LEVELS, SENTRY_AXIOS } from 'sentry-utils';

import config from 'config';

import { TOKEN_KEY } from 'api/constants/common';

import { deleteConfig } from 'helpers/other/commonUtils';

import { initApiClient, ClientError } from 'welltech-web-api-client';
import sentry from '../Sentry/SentryInstance';

function ApiClient() {
    return initApiClient({
        apiUrl: config.API_URL,
        apiVersion: config.API_VERSION,
        apiKey: config.API_KEY,
        authTokenKey: TOKEN_KEY,
        retries: 3,
        extendResponse: true,
        onUpdateToken: (token) => {
            localStorage.setItem(TOKEN_KEY, token);
        },
        onRetry: (retryCount, error, requestConfig) => {
            sentry.logError(new Error(`RETRY: ${error.message}`), SENTRY_AXIOS, ERROR_LEVELS.WARNING, {
                ...requestConfig,
            });
        },
        onAuthorisationError: (error: ClientError) => {
            const errorStatus = error?.response?.status;

            if (errorStatus === 403) {
                deleteConfig();

                location.replace('/');
            }
        },
        errorHandler: (error: ClientError) => {
            const response = error?.response;
            const request = error?.request;
            const errorStatus = response?.status;

            const extras = {
                requestURL: request?.requestURL,
                requestHeaders: request?.requestHeaders,
                requestData: request?.requestData,
                responseData: response?.data,
                responseHeaders: response?.headers,
            };

            const level = Number(errorStatus) >= 500 ? ERROR_LEVELS.CRITICAL : ERROR_LEVELS.ERROR;

            sentry.logError(new Error(error.message), SENTRY_AXIOS, level, extras);
        },
    });
}

export default ApiClient();
