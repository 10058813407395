import { Provider } from 'wikr-core-analytics';
import { takeLatest } from 'redux-saga/effects';

// redux
import { SET_USER_ID } from 'redux/User/actionTypes';
import { setUserId } from 'redux/User/actions';

function* setUserIdWorker({ payload }: ReturnType<typeof setUserId>) {
    yield Provider.setUserId(payload);
}

export const setUserIdSaga = function* () {
    yield takeLatest(SET_USER_ID, setUserIdWorker);
};
