import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const selectTestania = (state: RootState) => state.testania;
const selectTestaniaName = createSelector(selectTestania, (testania) => testania.testaniaName);
const selectTestaniaLoginStatus = createSelector(selectTestania, (testania) => testania.testaniaLoginStatus);
const selectCompleteOBs = createSelector(selectTestania, (testania) => testania.completeOBs);
const selectCurrentBranchName = createSelector(selectTestania, (testania) => testania.currentBranchName);
const selectTestaniaOnboardingConfig = createSelector(selectTestania, (testania) => testania.onboardingConfig);

export {
    selectTestaniaName,
    selectCompleteOBs,
    selectCurrentBranchName,
    selectTestania,
    selectTestaniaLoginStatus,
    selectTestaniaOnboardingConfig,
};
