import { AnalyticSystems } from 'interfaces/Analytic/analytic';

export const deleteSlash = (word: string): string => {
    return word.replace(/\//g, '');
};

export const underscoreToSentenceCase = (str: string): string => {
    return (
        str
            .toLowerCase()
            .replace(/_/g, ' ')
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .replace(/(?: |\b)(\w)/g, function (key, p1) {
                return key.toUpperCase();
            })
    );
};

export const deleteSymbols = (string: string): string => {
    return string.toString().replace(/_/g, '').replace(/,/g, '');
};

export const replaceComaToDot = (value?: number | string): number => Number(value?.toString().replace(/,/g, '.'));

export const replaceUnderscoreToDash = (string: string): string => string.replace(/_/g, '-');

export const replaceDashToUnderscore = (screen: string): string => screen.replace(/-/g, '_');

export const replaceSlashToDash = (string: string): string => string.replace(/\//g, '-');

export const replaceSlashToUnderscore = (string: string): string => string.replace(/\//g, '_');

export const capitalizeFirstLetter = (string: string): string => {
    try {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } catch (e) {
        return string;
    }
};

export const getArrayFromString = (str?: string): AnalyticSystems => str?.split(',').map((a) => a?.trim()) || [];

export const addSeparatorToNumbers = (value: number): string => {
    return value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const locationNameRefactor = (value: string): string => {
    return value.replace('/', '').replace(/-/g, '_');
};

export const replaceValue = (strValue: string, replacedValue: string): string => {
    return strValue?.replace(replacedValue, '');
};

export const deleteCommaAndDot = (strValue: string): string => {
    return strValue?.replace(/[.,\s]/g, '');
};

export const snakeCaseToSpace = (value: string) => value?.split('_')?.join(' ');
