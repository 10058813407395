import { useSelector } from 'react-redux';
import React from 'react';

import { selectModals } from 'redux/Modals/selectors';

import { MODAL_LIST } from './constants';

const Modals = () => {
    // @ts-ignore
    const { modalId, modalProps } = useSelector(selectModals);

    // @ts-ignore
    const ModalComponent = modalId ? MODAL_LIST[modalId] : null;

    if (!ModalComponent) {
        return null;
    }

    return <ModalComponent {...modalProps} />;
};

export default Modals;
