import React, { memo } from 'react';
import classNames from 'classnames/bind';

import styles from './Header.module.css';

import { IHeaderProps } from './types';

const cx = classNames.bind(styles);

function Header({ float, left, right, withShadow, whiteTheme, maxZIndex }: IHeaderProps) {
    const headerClass = cx({
        header: true,
        float,
        withShadow,
        whiteTheme,
        maxZIndex,
    });

    return (
        <header className={headerClass}>
            <div className={styles.headerContainer}>
                <div className={styles.leftBlock}>{left}</div>
                <div className={styles.rightBlock}>{right}</div>
            </div>
        </header>
    );
}

export default memo(Header);
