import { install, gtag } from 'helpers/analytic/googleAnalytic';

export class GoogleAnalytics {
    constructor(googleId, tagType) {
        this.googleId = googleId;
        this.Google = gtag;
        this.tagType = tagType || 'gtag/js';
        this.inited = false;
    }

    init() {
        if (this.inited || !this.googleId) return;

        install(this.googleId, this.tagType);
        this.inited = true;
    }

    isInited() {
        return this.inited;
    }

    track(eventName) {
        this.Google('event', eventName);
    }

    purchase(eventName = 'purchase', data) {
        gtag('event', eventName, {
            transaction_id: data.order_id,
            value: data.value,
            currency: 'USD',
            dimension1: data.ab_test_name,
            items: [
                {
                    name: `Product - Price ${data.value}`,
                    category: data.payment,
                    quantity: 1,
                    price: data.value,
                    payment: data.payment,
                },
            ],
        });
    }
}

export const GoogleAds = new GoogleAnalytics();
